// src/components/CropStages.js
import React, { useState } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import "./cropStages.css";
import { WiRain, WiDaySunny } from "react-icons/wi";
import enctick from "../../assets/images/encircled_tick.png";
import notick from "../../assets/images/notick.png";
import enc from "../../assets/images/encircle.png";

const CropStages = ({ weatherData, currentCrop, season, seedingDate }) => {
  

  const seasonMonths = {
    rabi: "Oct - Mar",
    kharif: "Jun - Sep",
    zaid: "Mar - Jun",
  };
  const monthDuration = seasonMonths[season.toLowerCase()] || "N/A";
  const today = new Date();
  const seeding = seedingDate ? new Date(seedingDate) : today;
  const diffInMs = today - seeding;
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  // console.log(diffInDays);

  return (
    <div className="right-container">
      <div className="upper-half">
        <div className="upper-half-1">
          <div className="upper-half-season">
            <button
              className={
                season.toLowerCase() === "rabi" ? "ga-btn-active" : "ga-btn"
              }
            >
              RABI
            </button>
            <button
              className={
                season.toLowerCase() === "kharif" ? "ga-btn-active" : "ga-btn"
              }
            >
              KHARIF
            </button>
            <button
              className={
                season.toLowerCase() === "zaid" ? "ga-btn-active" : "ga-btn"
              }
            >
              ZAID
            </button>
          </div>
          {/* <div className="upper-half-cropname">
            <strong>{currentCrop}</strong>
          </div> */}
          <div className="upper-half-month-dur">
            <strong>{monthDuration}</strong>
          </div>
        </div>
        <div className="upper-half-1 vert-scroll">
          {weatherData.map((item, index) => (
            <div
              key={index}
              className={`forecast-card-CS column js-sb ${
                item.isCurrent ? "current" : ""
              }`}
            >
              <div className="timeCS">{item.time}</div>
              <div className="iconCS">{item.icon}</div>
              <div className="tempCS">{item.temp}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="lower-half">
        <div className="lower-half-row js-se">
          <div
            className="column_cropstages aic wfc"
            style={{ position: "relative", marginTop: "10px" }}
          >
            <img 
                src={diffInDays > 1 ? enctick : notick} 
                className='fs-small' 
                alt="tick or no-tick"
            />
            <div
              style={{
                position: "absolute",
                fontSize: "10px",
                top: "2.5rem",
                textAlign: "center",
              }}
            >
              <b>Seeding</b>
            </div>
            <div
              style={{
                position: "absolute",
                fontSize: "10px",
                top: "4.5rem",
                textAlign: "center",
              }}
            >
              Day
            </div>
            <div
              style={{
                position: "absolute",
                fontSize: "10px",
                top: "5.5rem",
                textAlign: "center",
                width: "15vw",
              }}
            >
              0-10
            </div>
          </div>

          <div className="line"></div>

          <div
            className="column_cropstages aic wfc"
            style={{ position: "relative", marginTop: "10px" }}
          >
            <img 
                src={diffInDays >= 15 ? enctick : notick} 
                className='fs-small' 
                alt="tick or no-tick"
            />
            <div
              style={{
                position: "absolute",
                fontSize: "10px",
                top: "2.5rem",
                textAlign: "center",
              }}
            >
              <b>Fertilizer Application</b>
            </div>
            <div
              style={{
                position: "absolute",
                fontSize: "10px",
                top: "4.5rem",
                textAlign: "center",
              }}
            >
              Day
            </div>
            <div
              style={{
                position: "absolute",
                fontSize: "10px",
                top: "5.5rem",
                textAlign: "center",
                width: "15vw",
              }}
            >
              15-20
            </div>
          </div>

          <div className="line"></div>

          <div
            className="column_cropstages aic wfc"
            style={{ position: "relative", marginTop: "10px" }}
          >
            <img 
                src={diffInDays >= 30 ? enctick : notick} 
                className='fs-small' 
                alt="tick or no-tick"
            />
            <div
              style={{
                position: "absolute",
                fontSize: "10px",
                top: "2.5rem",
                textAlign: "center",
              }}
            >
              <b>Apply Pesticides</b>
            </div>
            <div
              style={{
                position: "absolute",
                fontSize: "10px",
                top: "4.5rem",
                textAlign: "center",
              }}
            >
              Day
            </div>
            <div
              style={{
                position: "absolute",
                fontSize: "10px",
                top: "5.5rem",
                textAlign: "center",
                width: "15vw",
              }}
            >
              30-35
            </div>
          </div>

          <div className="line"></div>

          <div
            className="column_cropstages aic wfc"
            style={{ position: "relative", marginTop: "10px" }}
          >
            <img 
                src={diffInDays >= 40 ? enctick : notick} 
                className='fs-small' 
                alt="tick or no-tick"
            />
            <div
              style={{
                position: "absolute",
                fontSize: "10px",
                top: "2.5rem",
                textAlign: "center",
              }}
            >
              <b>Harvesting</b>
            </div>
            <div
              style={{
                position: "absolute",
                fontSize: "10px",
                top: "4.5rem",
                textAlign: "center",
              }}
            >
              Day
            </div>
            <div
              style={{
                position: "absolute",
                fontSize: "10px",
                top: "5.5rem",
                textAlign: "center",
                width: "15vw",
              }}
            >
              40-45{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CropStages;
