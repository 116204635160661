
import React,{useEffect,useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./HomePage.css";
import backgroundImg from "../../assets/image/farmers-scheme-istock.svg";
import googlelogo from "../../assets/image/google.svg";
import SoilImage from '../../assets/image/SoilTech.svg'

function HomePage() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [formData, setFormData] = useState({ fullname: "", email: "", password: "", confirmpassword: "" });
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();
  localStorage.setItem("Email",formData.email);

  // Listen for window resize to adjust layout
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "fullname") {
      // Capitalize the first letter and make the rest lowercase
      const formattedName = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      setFormData((prevData) => ({ ...prevData, fullname: formattedName }));
    } else if (name === "email") {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@(aivot\.ai|aivot\.in)$/;
      if (!emailPattern.test(value)) {
        setEmailError("Only @aivot.ai and @aivot.in emails are allowed.");
      } else {
        setEmailError("");
      }
      setFormData((prevData) => ({ ...prevData, email: value }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if(formData.password!==formData.confirmpassword){
        alert("Password didn't match");
        return;
      }
      if (!formData.fullname || !formData.email || !formData.password || !formData.confirmpassword || !isChecked) {
        alert("All fields must be filled and Terms of Use must be accepted.");
        return;
      }
      // if (!isChecked) {
      //   alert("You must agree to the Terms of Use");
      //   return; // Exit the function if checkbox is not checked
      // }

      setLoading(true);
      



      const response = await axios.post("https://soiltech.ai/api/login/register", formData);
      if (response.status === 200)
      {
        alert('Email already Registered')
      
      }
      else if(response.status===201){
        navigate("/otp"); // Redirect to OTP page on success
      }
    
    } catch (error) {
      console.error("Registration failed:", error);
    }
    finally {
      setLoading(false); // Stop loading after the request completes
    }
  };

  // console.log(formData)
  return (
    <div className="container">

      <div className="left-panel">
        {isMobile ?(
          <div>
          <img
          src={backgroundImg} 
          alt="Digital Farming"
          className="background-img"
        />
        <div className="text-overlay">
          <div className="text-layout">
          <img src={SoilImage} alt="Logo" className="logo" />
            <h1>DIGITAL FARMING WITH POWER OF AI</h1>
          </div>
        </div>
        </div>

      ):(<div>
       
        <div className="text-overlay">
          <div className="text-layout">
            <h1>DIGITAL FARMING WITH POWER OF AI</h1>
          </div>
        </div>
        <div className="text-layout2">
          <p className="text-layout2-text">
            Today, we create innovative solutions to the challenges that
            consumers face in both their everyday lives and events.
          </p>
        </div></div>)}
      </div>

      <div className="right-panel">
        { isMobile? (
          <div className="signup-container">
          <h2>Welcome to Soil Tech</h2>
          <p>Getting started is easy</p>
          <form className="signup-form" onSubmit={handleSubmit}>
            <label>Full Name</label>
            <input type="text" placeholder="Ram" name='fullname'  onChange={handleInputChange} />

            <label>Enter Email</label>
            <input type="email" placeholder="Ram28@gmail.com" name='email' onChange={handleInputChange}/>
            {emailError && <p style={{ color: "red",fontSize:'0.5em' }}>{emailError}</p>}

            <label>Enter Password</label>
            <input type="password" placeholder="******" name='password' onChange={handleInputChange} />
            

            <label>Confirm Password</label>
            <input type="password" placeholder="******" name='confirmpassword' onChange={handleInputChange} />

            <button type="submit" className="submit-btn" disabled={loading || emailError}>
            {loading ? 'Loading...' : 'Register'}
            </button>
            {loading && <p>Submitting your registration...</p>} {/* Loading message */}

            <div className="terms">
              <input type="checkbox" style={{width: '10px'}}
               checked={isChecked}
               onChange={handleCheckboxChange}/> 
              <p className="termsText">
             
                By continuing, you indicate that you read and agreed to the
                Terms of Use
              </p>
            </div>
          </form>
          <p>Or continue with</p>
          <div className="social-signin">
            <div className="googlelayout">
            <div className="googlewrap">
            <img src={googlelogo} alt="Logo" className="googlelogo"/>
            <p className="googletext">Google</p>
            </div>
            </div>
           
            </div>
            <div className="login-prompt-container">
            <p className="login-text">
             Have an account? <a href="/" className="login-link">Log In!</a>
            </p>
            </div>

        </div>) :

       (
       <>
       {/* <Header/> */}
       
        <div className="signup-header-container">
       <div className="logo-container">
        {/* You can replace this with an actual image tag if needed */}
        <img src={SoilImage} alt="Logo"  className="logo" />
      </div>
      <div className="login-prompt-container">
        <p className="login-text">
          Have an account? <a href="/" className="login-link">Log In!</a>
        </p>
       </div>
       </div>
       <div className="signup-container">
          <h2>Welcome to Soil Tech</h2>
          <div className="Getting-Started">
          <p>Getting started is easy</p>
          <div className="social-signin">
            <div className="googlelayout">
            <div className="googlewrap">
            <img src={googlelogo} alt="Logo" className="googlelogo"/>
            <p className="googletext">Google</p>
            </div>
            </div>
            
          </div>
          </div>
          <p>Or continue with</p>

          <form className="signup-form" onSubmit={handleSubmit}>
            <label>Full Name</label>
            <input type="text" placeholder="Ram" name='fullname'  onChange={handleInputChange}/>

            <label>Enter Email</label>
            <input type="email" placeholder="Ram28@gmail.com" name='email' onChange={handleInputChange} />
            {emailError && <p style={{ color: "red" }}>{emailError}</p>}

            <label>Enter Password</label>
            <input type="password" placeholder="******"  name='password' onChange={handleInputChange}/>

            <label>Confirm Password</label>
            <input type="password" placeholder="******" name='confirmpassword' onChange={handleInputChange}/>

            <button type="submit" className="submit-btn" disabled={loading||emailError}>
            {loading ? 'Loading...' : 'Register'}
            </button>
            {loading && <p>Submitting your registration...</p>} {/* Loading message */}

            <div className="terms">
              <input type="checkbox" 
               checked={isChecked}
               onChange={handleCheckboxChange}/>
              <p className="termsText">
                By continuing, you indicate that you read and agreed to the
                Terms of Use
              </p>
            </div>
          </form>
        </div></>)}

      </div>
    </div>
  );
}

export default HomePage;
