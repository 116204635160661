import React, { useState } from "react";
import soilreports from "../../assets/icons/soilreports.svg";
import soiltest from "../../assets/icons/soiltest.svg";
import insights from "../../assets/icons/insights.svg";
import logout from "../../assets/icons/logout.svg";
import SoilImage from '../../assets/image/SoilTech.svg';
import Hambager_in from '../../assets/icons/hambager_in.png'
import Hambager_out from '../../assets/icons/hambager_out.png'
import { useAuth } from "../../Authentication/Auth";
import "./Sidebar.css";
// import useNavigate from "react-router-dom";
import { NavLink,Link } from 'react-router-dom';
import { FaHome, FaFileAlt, FaFlask, FaLightbulb, FaSignOutAlt } from 'react-icons/fa';  // Import icons

const Sidebar = ({isMobileView,onClose}) => {
  const { signOutUser} = useAuth();
  const [isCollapsed, setIsCollapsed] = useState(true);  // State to track if the sidebar is collapsed
  // const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);  // Toggle the sidebar state
  };

  const handleSignout = (e) => {
    e.preventDefault(); // Prevent immediate navigation
    signOutUser(); // Execute sign-out logic
  };
  

  if (isMobileView) {
    return (
      <div className="sidebar fullSidebar mobile-fullSidebar">
        {/* Mobile Full View */}
        <div className="mobile-top-section">
          <img src={SoilImage} alt="Logo" className="logo-img" />
          <img src={Hambager_in} alt="Hambager" onClick={onClose} style={{width:"30px",height:"30px"}} />
        </div>

        <nav className="menu">
          <NavLink to="/dashboard" className="menu-item" activeClassName="active">
            <FaHome className="icon" />
            <span>Dashboard</span>
          </NavLink>
          <NavLink to="/soilreport" className="menu-item" activeClassName="active">
            <FaFileAlt className="icon" />
            <span>Soil Reports</span>
          </NavLink>
          <NavLink to="/soiltest" className="menu-item" activeClassName="active">
            <FaFlask className="icon" />  
            <span>Soil Test</span>
          </NavLink>
          <NavLink to="/logout" className="menu-item" activeClassName="active" onClick={handleSignout}>
            <FaSignOutAlt className="icon" />
            <span>Logout</span>
          </NavLink>
        </nav>

        <div className="upgrade-section">
          <button className="upgrade-button">
            Upgrade to PRO to get access to all Features!
          </button>
          <Link to="/buy-pro" className="buy-now">
            <button className="buy-now-button">Buy Now</button>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : 'fullSidebar'}`}>
      {/* Desktop View */}
      <div className="top-section">
        <div className="logo">
          {!isCollapsed && <img src={SoilImage} alt="Logo" className="logo-img" />}
        </div>
        <button className="toggle-btn" onClick={toggleSidebar}>
          {!isCollapsed && <img src={Hambager_in} alt="Hambager" style={{width:"30px",height:"30px"}} />}
          {isCollapsed && <img src={Hambager_out} alt="Hambager" style={{width:"30px",height:"30px"}} />}
        </button>
      </div>

      <nav className="menu">
        <NavLink to="/dashboard" className="menu-item" activeClassName="active">
          <FaHome className="icon" />
          {!isCollapsed && <span>Dashboard</span>}
        </NavLink>
        <NavLink to="/soilreport" className="menu-item" activeClassName="active">
          <FaFileAlt className="icon" />
          {!isCollapsed && <span>Soil Reports</span>}
        </NavLink>
        <NavLink to="/soiltest" className="menu-item" activeClassName="active">
          <FaFlask className="icon" />
          {!isCollapsed && <span>Soil Test</span>}
        </NavLink>
        <NavLink to="/logout" className="menu-item" activeClassName="active" onClick={handleSignout}>
          <FaSignOutAlt className="icon" />
          {!isCollapsed && <span>Logout</span>}
        </NavLink>
      </nav>

      <div className="upgrade-section">
        {!isCollapsed && (
          <>
            <button className="upgrade-button">
              Upgrade to PRO to get access to all Features!
            </button>
            <Link to="/buy-pro" className="buy-now">
              <button className="buy-now-button">Buy Now</button>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default Sidebar;