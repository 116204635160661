import { useState, useEffect } from "react";
import axios from "axios";
import { WiNightAltRain, WiNightClear, WiRain, WiDaySunny } from "react-icons/wi";

export const useWeatherData = (id) => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [humidity, setHumidity] = useState(null);
  const [moisture, setMoisture] = useState(null);
  const [temperature, setTemperature] = useState(null);
  const [fetchWeatherData, setFetchWeatherData] = useState([]);
  const [storedHour, setStoredHour] = useState(new Date().getHours());

  const averageArray = (arr) => arr.reduce((sum, value) => sum + value, 0) / arr.length;

  const reformatWeatherData = (data) => {
    const currentHourIndex = getCurrentHourIndex(data.times);
  
    return data.times.map((time, index) => {
      const isCurrent = index === currentHourIndex;
      const date = new Date(time);
      const hour = date.getHours();
      const isNight = hour >= 18 || hour < 4;
      const isRainy = data.rains[index] > 0;
  
      // Choose appropriate weather icon based on time of day and rain
      const icon = isNight
        ? isRainy ? <WiNightAltRain /> : <WiNightClear />
        : isRainy ? <WiRain /> : <WiDaySunny />;
  
      return {
        time: getTimePeriod(time), // Convert time to '12 AM' format
        icon, // Weather icon
        rainChance: `${Math.round(data.precipitations[index])}%`, // Convert precipitation to percentage
        temp: `${Math.ceil(data.temperatures[index])}°`, // Round temperature
        isCurrent, // Set isCurrent flag based on current time
      };
    });
  };

  const getTimePeriod = (time) => {
    const date = new Date(time);
    let hours = date.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert 24-hour format to 12-hour
    return `${hours} ${ampm}`;
  };
  
  // Helper function to get current hour index based on time array
  const getCurrentHourIndex = (times) => {
    const now = new Date();
    return times.findIndex(time => new Date(time).getHours() === now.getHours());
  };
  

  useEffect(() => {
    const updateHourIfChanged = () => {
      const currentHour = new Date().getHours();
      if (currentHour !== storedHour) {
        setStoredHour(currentHour);
      }
    };

    const intervalId = setInterval(updateHourIfChanged, 1000 * 60); // Check every minute

    updateHourIfChanged(); // Initial check

    return () => clearInterval(intervalId); // Clean up on unmount
  }, [storedHour]);

  useEffect(() => {
    const fetchAndStoreWeatherData = async () => {
      try {
        // Get user's location
        navigator.geolocation.getCurrentPosition(async (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);

          // Fetch weather data using axios
          const response = await axios.get(
            `https://api.open-meteo.com/v1/forecast`,
            {
              params: {
                latitude,
                longitude,
                hourly:
                  "temperature_2m,relative_humidity_2m,precipitation_probability,rain,soil_temperature_18cm,soil_moisture_3_to_9cm",
                timezone: "auto",
              },
            }
          );

          const data = response.data;

          // Send data to the server
          await axios.post(
            "https://soiltech.ai/api/data/store-weather-data",
            {
              id,
              latitude,
              longitude,
              weatherData: data,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          // console.log("Weather data stored successfully.");
        });
      } catch (error) {
        console.error("Error fetching or storing weather data:", error);
      }
    };

    fetchAndStoreWeatherData();
  }, [storedHour, id]);

  useEffect(() => {
    const getWeatherDataForDate = async () => {
      const now = new Date();
      const today = now.toISOString().split("T")[0];

      try {
        // Fetch data from the server
        const response = await axios.get(
          "https://soiltech.ai/api/data/get-weather-data",
          {
            params: {
              id: id,
              specificDate: today,
            },
          }
        );

        const data = response.data;

        setHumidity(averageArray(data.humidities));
        setMoisture(averageArray(data.soilMoistures));
        setTemperature(averageArray(data.soilTemps));
        setFetchWeatherData(reformatWeatherData(data));

        // console.log("Weather data for the specified date:", data);
      } catch (error) {
        console.error("Error retrieving weather data:", error);
      }
    };

    if (latitude && longitude) {
      getWeatherDataForDate();
    }
  }, [latitude, longitude, id]);

  return {
    latitude,
    longitude,
    humidity,
    moisture,
    temperature,
    fetchWeatherData,
  };
};

