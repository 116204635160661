// import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes,Route,Navigate} from 'react-router-dom';
import HomePage from './routes/HomePage/HomePage';
import OTPPage from './routes/OTPPage/OTPPage.js';
import SignIn from './routes/SignIn/Signin';
import ForgotPassword from './routes/ForgotPassword/ForgotPassword.js';
import ChangePassword from './routes/ChangePassword/ChangePassword.js';
import Dashboard from './routes/Dashboard/Dashboard';
import Soiltest from './routes/SoilTest/SoilTest';
import SoilReport from './routes/SoilReport/SoilReport';
import SoilReportPDF from './routes/ReportPdf/SoilTestReport.js';
import EditProfile from './routes/EditProfile/EditProfile';
import AddFarm from './routes/AddNewFarm/AddFarm.js';
import { AuthProvider,useAuth } from './Authentication/Auth.jsx';
import Recommendation from './routes/Recommendation/Recommendation.js';
//import PDF_Layout_Later from './routes/SoilReport/SoilreportLayout/Soilpdfreport.js';
import SoilReportPDFDown from './routes/ReportPdf/ReportPdf.jsx';


function App() {
  const ProtectedRoute = ({ element, ...rest }) => {
    const auth = useAuth();
   
    // Check if the user is logged in and has the correct role
    const isAuthenticated = auth.isLoggedIn;
   
    return isAuthenticated ? (
      element
    ) : (
      <Navigate to="/" replace />
    );
  };
  return (
    <Router>
      <AuthProvider>
      <Routes>
        {/* <Route path="/register" element={<HomePage/>}></Route> */}
        <Route path="/" element={<SignIn/>}/>
        <Route path="/signup" element={<HomePage/>}/>
        <Route path="/otp" element={<OTPPage/>}/>
        <Route path="/forgot-password" element={<ForgotPassword/>}/>
        <Route path="/change-password" element={<ChangePassword/>}/>
        <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard/>} />} />
        <Route path ="/soiltest" element={<ProtectedRoute element={<Soiltest/>}/>} />
        <Route path ="/soilreport" element={<ProtectedRoute element={<SoilReport/>}/>} />
        <Route path ="/editprofile" element={<ProtectedRoute element={<EditProfile/>}/>} />
        <Route path ="/addfarm" element={<ProtectedRoute element={<AddFarm/>}/>}/>
        <Route path ="/recommendation"element={<ProtectedRoute element={<Recommendation/>}/>} />
        <Route path ="/Soilreportpdf" element={<SoilReportPDF/>}/>
        <Route path ="/Soil-report-pdf-download" element={<SoilReportPDFDown/>}/>
      </Routes>
      </AuthProvider>
    </Router>
    
  );
}

export default App;
