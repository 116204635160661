import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import DHeader from "../../components/DashboardHeader/DHeader";
import Filepdf from "../../assets/icons/FilePdf.svg";
import Download from "../../assets/icons/Download.svg";
import Moonstar from "../../assets/icons/MoonStars.svg";
import rightaroow from "../../assets/icons/CaretRight.svg";
import { useNavigate } from "react-router-dom";
import GaugeChart from "../../components/GaugeChart/GaugeChart";
import WeatherCard from "../../components/WeatherCard/WeatherCard";
import WeatherForecast from "../../components/WeatherForecast/WeatherForecast";
import CropStages from "../../components/CropStage/CropStages";
import SoilAnalysis from "../../components/SoilAnalysis/SoilAnalysis";
import ChartComponent from "../../components/ChartComponent/ChartComponent";
import {useSoilData} from "./useSoilData"; 
import {useWeatherData} from "./useWeatherData";
import {useProfileImage} from "./useProfileImage";
import axios from "axios";

import "./Dashboard.css";
 
const Dashboard = () => {
  const [MicronutrientOpen, setMicronutrientOpen] = useState(false);
  const [frequencyOpen, setfrequencyOpen] = useState(false);
  
  const [timeRange, setTimeRange] = useState('month');
  const navigate = useNavigate();
  const userId = localStorage.getItem("GeneratedKey");
  const farmId=localStorage.getItem('FarmId');
  const { currentcrop,season_curr,seedingdate,soilData, insightsData, soilshi, error } = useSoilData(userId,farmId);
  const [currentId, setCurrentId] = useState(farmId);
  const {latitude,longitude,humidity,moisture,temperature,fetchWeatherData} = useWeatherData(userId);
  const {profileImage} = useProfileImage(userId);
  
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [selectedElement, setSelectedElement] = useState('nitrogen');
  localStorage.setItem('profileImage', profileImage);
  localStorage.setItem("soilMoisture",moisture);
  localStorage.setItem("currentAdress",address);
  localStorage.setItem("soilTemperature",temperature);
  localStorage.setItem("Humidity",humidity);
 
  useEffect(() => {
    const Updateid = async () => {
      const farmfetchid = localStorage.getItem("FarmId");
      setCurrentId(farmfetchid);
    };

    window.addEventListener("storageChange", Updateid);

    return () => {
      window.removeEventListener("storageChange", Updateid);
    };
  }, []); 

  
  const handleElementChange = (e) => {
    setSelectedElement(e.target.value);
  };
 
  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
  };
 
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600); // Mobile if screen width <= 768px
  };

  useEffect(() => {
    handleResize(); // Set initial state
    window.addEventListener("resize", handleResize); // Add event listener for resizing
    return () => window.removeEventListener("resize", handleResize); // Clean up listener
  }, []);
 
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".mdropdownlist") &&
        !event.target.closest(".fdropdownlist")
      ) {
        setMicronutrientOpen(false);
        setfrequencyOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
 
  const navigateToSoilReport = () => {
    navigate("/soilreport");
  };
 
  const navigateToSoilTest = () => {
    navigate("/soiltest");
  };
 
  useEffect(() => {
    const fetchLocation = async () => {
      // console.log("latitude and longitude are", latitude, longitude);
 
      if (latitude && longitude) {
        try {
          const response = await axios.post(
            "https://soiltech.ai/fastapi/reverse_geocode",
            {
              latitude: latitude,
              longitude: longitude,
            }
          );
 
          // Check if we have a valid response
          if (response.data && response.data.complete_address) {
            // console.log("The address is", response.data);
            setAddress(response.data.complete_address);
            setCity(response.data.city);
            setState(response.data.state);
          } else {
            // console.log("The address not found");
            setCity("");
            setState("");
            setAddress("");
          }
        } catch (err) {
          console.log("Error fetching address:", err);
          setCity("");
          setState("");
          setAddress("");
        }
      }
    };
 
    fetchLocation();
  }, [latitude, longitude]);
  // Function to fetch and send weather data
  // Call the function to execute

  return (
    <div className="dashboard-container">
      <div className="main-content-1">
        <Sidebar />
      </div>
      <div className="main-content-2">
        <DHeader isMobile={isMobile}/>
        <div className="main-content-dashboard">
          <div className="main-content-col1">
              <div className="dashboard-top">
                <div className="dashboard-card" onClick={navigateToSoilReport}>
                  <img
                    src={Filepdf} // Replace with your image URL
                    alt="Filepdf"
                    className="Filepdf"
                  />
                  <h5>Download Soil Reports</h5>
                  <img
                    src={Download} // Replace with your image URL
                    alt="Download"
                    className="Download"
                    onClick={navigateToSoilReport}
                  />
                </div>
                <div className="dashboard-card" onClick={navigateToSoilTest}>
                  <img
                    src={Moonstar} // Replace with your image URL
                    alt="Moonstar"
                    className="Moonstar"
                    onClick={navigateToSoilTest}
                  />
                  <h5>Perform a soil test</h5>
                  <img
                    src={rightaroow} // Replace with your image URL
                    alt="rightaroow"
                    className="rightaroow"
                  />
                </div>
                <div className="dashboard-Chart-card">
                {soilshi ? (
                      <GaugeChart soilshi={soilshi} />
                    ) : (
                      <p>Loading...</p>
                    )}
                </div>
              </div>
              <div className="dashboard-middle">
                <div className="insight-left">
                  <div className="insight-heading" style={{ margin: "15px 0px 16px 0px", fontWeight: "bold", fontSize: "18px" }}>
                    Insights
                  </div>
                  <div className="insight-left-container">
                  {insightsData.length > 0 ? (
                      insightsData.map((insight, index) => (
                        <p key={index} className="insight-left-paragraph">
                          {insight}
                        </p>
                      ))
                    ) : (
                      <p>No insights available</p> // Fallback message when insightsData is empty
                    )}
                </div>
                </div>
                <div className="insight-right">
                  <div className="insight-right-image">
                    <WeatherForecast city={city} state={state} />
                  </div>
                  <div className="insight-right-container">
                    <CropStages weatherData={fetchWeatherData} currentCrop={currentcrop} season={season_curr} seedingDate={seedingdate} />
                  </div>
                </div>
              </div>
              <div className="dashboard-bottom">
                <div className="graph-heading">Soil Health (NPK)</div>
                <div className="graph-heading-dropdowns">
                  <div className="graph-npk-dropdown">
                    <select value={selectedElement} onChange={handleElementChange}>
                      <option value="nitrogen">Nitrogen (N)</option>
                      <option value="phosphorus">Phosphorus (P)</option>
                      <option value="potassium">Potassium (K)</option>
                    </select>
                  </div>
                  <div className="graph-year-dropdown">
                      <select
                        value={timeRange}
                        onChange={handleTimeRangeChange}
                      >
                        <option value="month">Monthly</option>
                        <option value="week">Week</option>
                        <option value="year">Year</option>
                      </select>
                  </div>
                </div>
              </div>
              <div className="chart-section">
                <ChartComponent timeRange={ timeRange } selectedElement={selectedElement}/>
              </div>
          </div>
          <div className="main-content-col2">
            <div className="dashboard-weather">
              <div className="weatherforecast">
                <WeatherCard weatherData={fetchWeatherData} />
              </div>
              <div className="soilanalysis">
                <SoilAnalysis data={soilData} />

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default Dashboard;