import React,{useEffect,useState} from "react";
import { useNavigate } from "react-router-dom"; 
import "./ForgotPassword.css";
import backgroundImg from "../../assets/image/farmers-scheme-istock.svg";
import SoilImage from '../../assets/image/SoilTech.svg'
import axios from "axios";


function ForgotPassword() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  // Listen for window resize to adjust layout
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

 
  const handleSendOTP = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post('https://soiltech.ai/api/login/forgot-password', { email });
      alert(response.data.message);

      navigate("/change-password", { state: { email } });
    } catch (error) {
       if (error.response && error.response.status === 404) {
        // Error 404 for email not registered
        alert("Email not registered. Please check your email or sign up.");
      } else if (error.response && error.response.status === 500) {
        alert("OTP sending failed. Please try again later.");}
      else {
        // Generic error
        alert("Failed to send OTP. Please try again.");
      }
    }
  };
  return (
    <div className="container">
        
      <div className="left-panel-forgot">
        {isMobile ?(
          <div>
          <img
          src={backgroundImg} // Replace with your image URL
          alt="Digital Farming"
          className="background-img"
        />
        <div className="text-overlay">
          <div className="text-layout">
          <img src={SoilImage} alt="Logo" className="logo" />
            <h1>DIGITAL FARMING WITH POWER OF AI</h1>
          </div>
        </div>
        </div>
        
      ):(<div>
       
        <div className="text-overlay">
          <div className="text-layout">
            <h1>DIGITAL FARMING WITH POWER OF AI</h1>
          </div>
        </div>
        <div className="text-layout2">
          <p className="text-layout2-text">
            Today, we create innovative solutions to the challenges that
            consumers face in both their everyday lives and events.
          </p>
        </div></div>)}
      </div>
        

      <div className="right-panel-forgot">
        { isMobile? (
          <div className="signup-container">
          <h2>Welcome to Soil Tech</h2>
          <p>Getting started is easy</p>
         
         
          <form className="ForgotPassword-form"onSubmit={handleSendOTP}>
            <label>Forgot Password</label>
            <p style={{fontSize:'0.6em',fontStyle:'normal',fontWeight:'500',color: 'var(--Neutral-700, #4B4B4B)'}}>Enter your email and we’ll send you a link and OTP to reset your password</p>
            <input type="email"  value={email}
             onChange={(e) => setEmail(e.target.value)} placeholder="Enter Your registered email"   required/>

            <button type="submit" className="sendotp-btn" disabled={isLoading}>
            {isLoading ? "Sending OTP..." : "SEND OTP"}
            </button>   
          </form>
       
          <div className="forgot-prompt-container">
           <p className="login-text">
            Don’t have an account?<a href="/signup" className="login-link">Sign up!</a>
           </p>
            </div>
          
        </div>) : 
       
       (
       <>
        <div className="forgot-header-container">
          <div className="logo-container">
           <img src={SoilImage} alt="Logo"  className="logo" />
           </div>
           <div className="forgot-prompt-container">
           <p className="login-text">
            Don’t have an account?<a href="/signup" className="login-link">Sign up!</a>
           </p>
            </div>
          </div>
        <div className="signup-container">
          <h2>Welcome to Soil Tech</h2>
          <p>Getting started is easy</p>
          
        

          <form className="ForgotPassword-form" onSubmit={handleSendOTP}>
            <label>Forgot Password</label>
            <p style={{fontSize:'0.7em',fontStyle:'normal',fontWeight:'500',color: 'var(--Neutral-700, #4B4B4B)'}}>Enter your email and we’ll send you a link and OTP to reset your password</p>
            <input type="email"  value={email}
             onChange={(e) => setEmail(e.target.value)} placeholder="Enter your registered email"     required />

            <button type="submit" className="sendotp-btn" disabled={isLoading}>
             {isLoading ? "Sending OTP..." : "SEND OTP"}
            </button>

         
          </form>
        </div></>)}

      </div>
    </div>
  );
}

export default ForgotPassword;
