import React, { useState,useRef, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import DHeader from "../../components/DashboardHeader/DHeader";
import Country from "../../assets/countries.json";
import editIcon from "../../assets/icons/EditIcon.svg";
import { useNavigate } from "react-router-dom";
import defaultProfileImage from "../../assets/image/farmer_image.png";
import "./AddFarm.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddFarm() {
  const fileInputRef = useRef(null);
  const id = localStorage.getItem("GeneratedKey");
  const [isMobile, setIsMobile] = useState(false);
  const [profileImage, setProfileImage] = useState(defaultProfileImage);
  const farmIds = ["abc458", "cbz765", "ktm928", "r15abz", "xcd130"];
  const farmId=localStorage.getItem('FarmId');
  const setid = farmId ?farmId:farmIds[0];
  const [currentId, setCurrentId] = useState(setid);
  const [farmCount, setFarmCount] = useState(farmIds.indexOf(currentId)+1);
  // const handleEditClick = () => {
  //   // Trigger the hidden file input when edit icon is clicked
  //   document.getElementById("fileInput").click();
  // };

  // const [farmnumber, setFarmNumber] = useState(0);
  const [formData, setFormData] = useState({
    AreaofLand: "",
    Date: "",
    NameofFarmer: "",
    Weather: "",
    Country: "",
    SeedingDate: "",
  });

  const handleChange = (e, field) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  useEffect(() => {
    const fetchProfileImage = async () => {
      const imageid=id+String(currentId)
      try {
        const response = await axios.get('https://soiltech.ai/api/getProfileImage',{
          params: {
            id:imageid,
          }
        });
        if(response.data?.imageUrl){
        setProfileImage(response.data.imageUrl); }
        else{
          setProfileImage("")
        }
         // Save the file path to state
      } catch (error) {
        console.error('Error fetching profile image:', error);
      }
    };

    fetchProfileImage();  // Fetch profile image on component mount
  }, [currentId]);

  useEffect(() => {
    const Updateid = async () => {
      const farmfetchid = localStorage.getItem("FarmId");
      setFarmCount(farmIds.indexOf(farmfetchid)+1);
      setCurrentId(farmfetchid);
    };

    window.addEventListener("storageChange", Updateid);

    return () => {
      window.removeEventListener("storageChange", Updateid);
    };
  }, []); // Dependency array (include `id` if it changes too)

  

  useEffect(() => {
    const fetchFarmDetails = async () => {
      try {
        // Make the POST request to the API with data in the body
        const response = await axios.post(
          "https://soiltech.ai/api/farmData/farmdetails",
          {
            Id: id, // Include Id in the body
            farmId:currentId, // Include farmId in the body
          }
        );

        // Set the farm details in state
        const inputData=response.data?.farmData
        if(inputData){
        setFormData(inputData);
        }
        else{
          setFormData({
          AreaofLand: "",
          Date: "",
          NameofFarmer: "",
          Weather: "",
          Country: "",
          SeedingDate: "",
          })
        }
      } catch (err) {
        console.error("Error fetching farm details:", err);
      }
    };
    fetchFarmDetails();
  }, [currentId]); // Dependency array (include `id` if it changes too)

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {

      const response = await axios.post(
        "https://soiltech.ai/api/farmData/save",
        { Id: id, formData: formData, farmId: currentId }
      );
      toast.success("Successfully Updated!", {
        position: "top-right",
        autoClose: 500, // time in milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: "green",
          color: "white",
        },
      });
    } catch (error) {
      toast.error("Submission Failed. Please try again.", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: "red",
          color: "white",
        },
      });
    }
  };

  const navigate = useNavigate();
  const handleClick = () => {
    fileInputRef.current.click();
  };


  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      // Create a URL for the new uploaded image and set it to profileImage state
      const newImage = URL.createObjectURL(event.target.files[0]);
      setProfileImage(newImage);
    }
  };
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600); // Mobile if screen width <= 768px
  };

  useEffect(() => {
    handleResize(); // Set initial state
    window.addEventListener("resize", handleResize); // Add event listener for resizing
    return () => window.removeEventListener("resize", handleResize); // Clean up listener
  }, []);
  const handleAddFarmClick = () => {
    if(farmCount === 5) {
      setFarmCount(1)
    } else {
      setFarmCount(farmCount + 1); // Increment farm count
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // You can handle the uploaded file here (e.g., display it or upload it to a server)
      const imageUrl = URL.createObjectURL(file); // Create a URL for the uploaded image
      // setSelectedImage(imageUrl);
      const formData = new FormData();
      formData.append("image", file);
      const imageid=id+String(currentId)
      formData.append("id",imageid);

      try {
        // Upload image to the server
        const response = await axios.post(
          "https://soiltech.ai/api/ProfileUpload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data?.imageUrl) {
         setProfileImage(response.data.imageUrl)
        }
      } catch (error) {
        console.error("Error uploading the image", error);
      }
    }
  };



  useEffect(() => {
    console.log('farmcount', farmCount)
    localStorage.setItem("FarmId", farmIds[farmCount - 1]);
    console.log('localStorage set', farmIds[farmCount-1]);
    window.dispatchEvent(new Event("formIdFromAddFarm"));
  }, [farmCount]);

  return (
    <div className="AddFarm-Container">
      <div className="AddFarm-Container1">
        <Sidebar />
      </div>
      <div className="AddFarm-Container2">
        <DHeader isMobile={isMobile} />
        <div className="AddFarm-maincontent">
          <div className="AddFarm-maincontent-top">
            <div
              className="AddFarm-maincontent-top-first-half"
              style={{ width: "45%" }}
            ></div>
            <div className="AddFarm-maincontent-top-second-half">
              <div className="AddFarm-Image">
                <div className="Addfarm-avatar-wrapper">
                  <img
                    src={profileImage}
                    alt="Profile Avatar"
                    className="Addfarm-avatar-image"
                  />
                  <div className="Addfarm-edit-badge" onClick={handleClick}>
                    <img
                      src={editIcon}
                      alt="Edit Icon"
                      className="AddFarm-edit-icon"
                    />
                  </div>
                  <p className="AddFarm-profile-text">Edit Profile</p>
                </div>
                <input
                 type="file"
                 ref={fileInputRef}
                 style={{ display: "none" }}
                 onChange={handleFileChange}
                />
              </div>
              <div className="AddFarm-Button">
                <button
                  className="AddFarm-Edit-Button"
                  onClick={handleAddFarmClick}
                >
                  {`+ Add a New Farm ${farmCount}/5`}
                </button>
              </div>
            </div>
          </div>
          <div className="AddFarm-maincontent-middle">
            <div className="AddFarm-form1">
              <label className="AddFarm-form1-label"> Area of Land</label>
              <input
                type="number"
                placeholder="1.2 hectare"
                className="AddFarm-form1-input"
                value={formData.AreaofLand}
                onChange={(e) => handleChange(e, "AreaofLand")}
              />

              <label className="AddFarm-form1-label"> Choose Date</label>
              <input
                type="date"
                className="AddFarm-form2-input"
                name="date"
                value={formData.Date}
                onChange={(e) => handleChange(e, "Date")}
              />

              <label className="AddFarm-form1-label">Name of Farmer</label>
              <input
                type="text"
                placeholder="Ram"
                className="AddFarm-form1-input"
                value={formData.NameofFarmer}
                onChange={(e) => handleChange(e, "NameofFarmer")}
              />
            </div>
            <div className="AddFarm-form2">
              <label className="AddFarm-form1-label"> Weather</label>
              <input
                type="text"
                placeholder="Summer"
                className="AddFarm-form1-input"
                value={formData.Weather}
                onChange={(e) => handleChange(e, "Weather")}
              />

              <label className="AddFarm-form1-label">Country</label>
              <select
                name="country"
                className="AddFarm-form2-input"
                value={formData.Country}
                onChange={(e) => handleChange(e, "Country")}
              >
                <option value="">Select Country</option>
                {Country.countries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>

              <label className="AddFarm-form1-label">Seeding Date</label>
              <input
                type="date"
                className="AddFarm-form2-input"                             
                name="date"
                value={formData.SeedingDate}
                onChange={(e) => handleChange(e, "SeedingDate")}
              />
            </div>
          </div>
          <div className="AddFarm-maincontent-bottom">
            <button className="AddFarm-update-button" onClick={handleUpdate}>
              Update
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
      
    </div>
  );
}
export default AddFarm;
