import React,{useEffect,useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./ChangePassword.css";
import backgroundImg from "../../assets/image/farmers-scheme-istock.svg";
import axios from "axios";

import SoilImage from '../../assets/image/SoilTech.svg'

function ChangePassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const email = location.state?.email;


  // Listen for window resize to adjust layout
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (!otp || !newPassword || !confirmPassword) {
      alert("Please fill out all fields.");
      return;
    }
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
    try {
      const response = await axios.post('https://soiltech.ai/api/login/reset-password', {email, otp, newPassword });
      if (response.status === 200) { // Check for 200 status
        //  console.log("entering the function");
        //  console.log("Password reset successful:", response.data.message);
        alert(response.data.message);
        navigate("/",{ replace: true }); // Navigate to login page upon success
      }
    } catch (error) {
      alert("Failed to reset password. Please try again.");
    }
  };
  return (
    <div className="container">
        
      <div className="left-panel-change">
        {isMobile ?(
          <div>
          <img
          src={backgroundImg} // Replace with your image URL
          alt="Digital Farming"
          className="background-img"
        />
        <div className="text-overlay">
          <div className="text-layout">
          <img src={SoilImage} alt="Logo" className="logo" />
            <h1>DIGITAL FARMING WITH POWER OF AI</h1>
          </div>
        </div>
        </div>
        
      ):(<div>
       
        <div className="text-overlay">
          <div className="text-layout">
            <h1>DIGITAL FARMING WITH POWER OF AI</h1>
          </div>
        </div>
        <div className="text-layout2">
          <p className="text-layout2-text">
            Today, we create innovative solutions to the challenges that
            consumers face in both their everyday lives and events.
          </p>
        </div></div>)}
      </div>
        

      <div className="right-panel-change">
        { isMobile? (
          <div className="signup-container">
          <h2>Welcome to Soil Tech</h2>
          <p>Getting started is easy</p>
          <form className="ChangePassword-form" onSubmit={handlePasswordReset}>
            <label> Change Password</label>

            <div className="Input-box">
            <input type="password"  value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)} placeholder="Enter New Password"  required/>
            <input type="password"   value={confirmPassword}
             onChange={(e) => setConfirmPassword(e.target.value)}placeholder="Confirm New Password"  required/>
            <input type="text"  value={otp}
             onChange={(e) => setOtp(e.target.value)} placeholder=" Enter OTP" required />
            </div>
            <p style={{fontSize:'0.7em',fontStyle:'normal',fontWeight:'500',color: 'var(--Neutral-700, #4B4B4B)',alignContent:'start'}}>Enter the OTP sent on your registered email ID</p>
           

            <button type="submit" className="change-submit-btn" >
              SUBMIT
            </button>
          </form>
         
         
       
          <div className="change-prompt-container">
           <p className="login-text">
            Don’t have an account?<a href="/signup" className="login-link">Sign up!</a>
           </p>
            </div>
          
        </div>) : 
       
       (
       <>
        <div className="change-header-container">
          <div className="logo-container">
           <img src={SoilImage} alt="Logo"  className="logo" />
           </div>
           <div className="change-prompt-container">
           <p className="login-text">
            Don’t have an account?<a href="/signup" className="login-link">Sign up!</a>
           </p>
            </div>
          </div>
        <div className="signup-container">
          <h2>Welcome to Soil Tech</h2>
          <p>Getting started is easy</p>
          
        

          <form className="ChangePassword-form" onSubmit={handlePasswordReset}>
            <label> Change Password</label>

            <div className="Input-box">
            <input type="password"  value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)} placeholder="Enter New Password"  required/>
            <input type="password"   value={confirmPassword}
             onChange={(e) => setConfirmPassword(e.target.value)}placeholder="Confirm New Password"  required/>
            <input type="text"  value={otp}
             onChange={(e) => setOtp(e.target.value)} placeholder=" Enter OTP" required />
            </div>
            <p style={{fontSize:'0.7em',fontStyle:'normal',fontWeight:'500',color: 'var(--Neutral-700, #4B4B4B)',alignContent:'start'}}>Enter the OTP sent on your registered email ID</p>
           

            <button type="submit" className="change-submit-btn" >
              SUBMIT
            </button>
          </form>
        </div></>)}

      </div>
    </div>
  );
}

export default ChangePassword;
