import React, { createContext, useContext, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authData, setAuthData] = useState({
        token: localStorage.getItem("token"),
        googleToken: localStorage.getItem("googleToken"),
        userProfile: localStorage.getItem("userProfile") ? JSON.parse(localStorage.getItem("userProfile")) : null,
    });

    const storeAuthDataInLS = (serverToken) => {
        localStorage.setItem("token", serverToken);
        setAuthData({
            ...authData,
            token: serverToken,
        });
    };

    // Store Google token and user data after login
    const handleGoogleLogin = (response) => {
        const googleToken = response.tokenId; // This is the token from Google
        const userProfile = response.profileObj; // Google user profile object
        localStorage.setItem("googleToken", googleToken);
        localStorage.setItem("userProfile", JSON.stringify(userProfile));

        setAuthData({
            ...authData,
            googleToken: googleToken,
            userProfile: userProfile,
        });
    };

    const handleGoogleFailure = (error) => {
        console.error("Google Sign-In Failure:", error);
    };

    const signOutUser = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("GeneratedKey");
        localStorage.removeItem("Humidity");
        localStorage.removeItem("currentAdress");
        localStorage.removeItem("soilMoisture");
        localStorage.removeItem("soilTemperature");
        localStorage.removeItem("username");
        localStorage.removeItem("profileImage");
        localStorage.removeItem("FarmId");
        setAuthData({
            token: null,
            googleToken: null,
            userProfile: null,
        });
    };

    const isLoggedIn = !!authData.token;

    return (
        <AuthContext.Provider value={{ ...authData, isLoggedIn, handleGoogleLogin, handleGoogleFailure, storeAuthDataInLS, signOutUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const authContextValue = useContext(AuthContext);
    if (!authContextValue) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return authContextValue;
};
