import React from "react";

import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

import green_plant from "../../assets/images/green_plant_cropped.png";
import red_plant from "../../assets/images/red_plant_cropped.png";
import orange_plant from "../../assets/images/orange_plant_cropped.png";
import yellow_plant from "../../assets/images/yellow_plant_cropped.png";

import soiltech from "../../assets/image/soiltech.png";

// Define the styles

const styles = StyleSheet.create({
  page: {
    padding: 10,
  },

  header: {
    flexDirection: "row",

    alignItems: "center",

    marginBottom: 10,
  },

  logo: {
    width: "4cm",
    height: "2cm",
  },

  title: {
    marginLeft: 10,

    fontSize: 20,

    fontWeight: "bold",
  },

  container: {
    flexDirection: "row",

    border: "1px solid black",
  },

  leftSection: {
    width: "50%",

    backgroundColor: "#61AB4F",

    padding: 10,

    color: "white",
  },

  rightSection: {
    width: "50%",

    backgroundColor: "#26669E",

    padding: 10,

    color: "white",
  },

  sectionTitle: {
    fontSize: 14,

    fontWeight: "bold",
  },

  sectionText: {
    fontSize: 12,
  },

  table: {
    display: "table",

    width: "99%",

    marginTop: 10,

    borderCollapse: "collapse",
  },

  tableRow: {
    flexDirection: "row",
  },

  tableCol: {
    width: "33%",

    border: "1px solid #61AB4F",

    flexDirection: "row",
  },

  imageContainer: {
    flex: 1,
    marginRight: 10,
  },

  image: {
    width: 20,

    height: 20,

    resizeMode: "cover",
  },

  detailsContainer: {
    flex: 2,
  },

  tableCell: {
    fontSize: 10,

    color: "#000",

    height: "1cm",

    margin: 1,
  },

  RecommendationBox: {
    border: "1px solid black",

    padding: 10,

    marginTop: 10,

    height: "4cm",

    backgroundColor: "#D5D5D5",
  },

  RecommendationTitle: {
    fontSize: 10,

    fontWeight: "bold",

    marginBottom: 8,

    backgroundColor: "#61AB4F",

    width: "4cm",

    height: "1cm",

    paddingLeft: 8,
  },
  recommendationText: {
    fontSize: 10,

    paddingBottom: 2,
  },

  photoBox: {
    border: "1px solid black",

    padding: 10,

    marginTop: 10,

    height: "5cm",
  },

  photoTitle: {
    fontSize: 12,

    fontWeight: "bold",

    marginBottom: 10,
  },

  photoDescription: {
    fontSize: 14,

    marginBottom: 10,
  },

  photoContainer: {
    flexDirection: "row",

    justifyContent: "space-between",

    height: "3cm",
  },

  photoItem: {
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "23%",
    textAlign: "center",
    height: "3cm",
    alignItems: "center",
  },

  image: {
    width: "100%",
  },

  descImage: {
    width: "2.5cm",
    height: "3cm",
  },
  rectableRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 1,
  },
  rectableHeader: {
    fontWeight: "bold",
    flex: 1,
    textAlign: "center",
    fontSize: 10,
    height: "1cm",
    padding: "0 0 2vh 0",
  },
  rectableCell: {
    flex: 1,
    textAlign: "center",
    height: "1cm",
    fontSize: 8,
  },
});

// Define the document component

const Quixote = ({ apiResponse }) => {
  const image_array = [red_plant, orange_plant, yellow_plant, green_plant];
  // console.log(apiResponse);
  const colors = ["red", "orange", "yellow", "green"];
  const description_array = [
    "High, Acid, Saline, Highly Alkaline, Low (OC, OM)",
    "Low Deficient",
    "Medium (OC, OM, mbc)",
    "Medium, Neutral, Sufficient, Alkaline\nSlightly Acidic, High (OC, OM, mbc)",
  ];

  const keys_array = [];
  Object.entries({
    pH: "pH",
    EC: "EC",
    "Organic Carbon": "OrganicCarbon",
    "Organic Matter": "OrganicMatter",
    "Available Nitrogen": "Nitrogen",
    "Available Phosphorus": "Phosphorus",
    "Available Potassium": "K_Potassium",
    "Available Sulphur": "Sulphur",
    "Available Copper": "Cu",
    "Available Iron": "Fe",
    "Available Zinc": "Zn",
    "Available Boron": "Boron",
    "Soil Health Index (SHI)": "SHI",
    "Bulk Density": "bulkdensity",
  }).map((i) => keys_array.push(i));

  const ranges = {
    Boron: [0.5, Infinity],
    Cu: [0.2, Infinity],
    EC: [0, 1],
    Fe: [2.5, Infinity],
    K_Potassium: [110, 280],
    Mn: [0, 1],
    Nitrogen: [280, 560],
    OrganicCarbon: [0.5, 0.75],
    OrganicMatter: [0.86, 1.29],
    Phosphorus: [10, 25],
    Sulphur: [10, 30],
    Zn: [0.6, Infinity],
    pH: [6.5, 7.5],
    SHI: [0, 1],
    bulkdensity: [1, 5],
  };
  const cropGrowingMonthsMap = {
    wheat: { growingMonths: "November-March", season: "Rabi" },
    barley: { growingMonths: "November-March", season: "Rabi" },
    mustard: { growingMonths: "October-January", season: "Rabi" },
    peas: { growingMonths: "October-February", season: "Rabi" },
    gram: { growingMonths: "October-January", season: "Rabi" },
    lentils: { growingMonths: "October-January", season: "Rabi" },
    rice: { growingMonths: "June-October", season: "Kharif" },
    maize: { growingMonths: "June-October", season: "Kharif" },
    cotton: { growingMonths: "April-August", season: "Kharif" },
    sorghum: { growingMonths: "July-October", season: "Kharif" },
    bajra: { growingMonths: "June-September", season: "Kharif" },
    pea: { growingMonths: "June-October", season: "Kharif" },
    groundnut: { growingMonths: "June-September", season: "Kharif" },
    soybean: { growingMonths: "June-September", season: "Kharif" },
    watermelon: { growingMonths: "February-May", season: "Zaid" },
    cucumber: { growingMonths: "February-May", season: "Zaid" },
    muskmelon: { growingMonths: "February-May", season: "Zaid" },
    bittergourd: { growingMonths: "March-June", season: "Zaid" },
    pumpkin: { growingMonths: "February-May", season: "Zaid" },
    summermaize: { growingMonths: "March-June", season: "Zaid" },
    cowpea: { growingMonths: "April-July", season: "Zaid" },
    tea: { growingMonths: "Throughout the year", season: "Perennial" },
    coffee: { growingMonths: "Throughout the year", season: "Perennial" },
    rubber: { growingMonths: "Throughout the year", season: "Perennial" },
    tobacco: { growingMonths: "October-January", season: "Rabi" },
    sugarcane: { growingMonths: "October-February", season: "Perennial" },
    coconut: { growingMonths: "Throughout the year", season: "Perennial" },
    tomato: {growingMonths: "January-April, September-October",season: "Zaid",},
    potato: { growingMonths: "October-January", season: "Rabi" },
    onion: { growingMonths: "October-February", season: "Rabi" },
    brinjal: { growingMonths: "January-July", season: "Zaid" },
    cabbage: { growingMonths: "October-February", season: "Rabi" },
    cauliflower: { growingMonths: "October-January", season: "Rabi" },
    carrot: { growingMonths: "October-January", season: "Rabi" },
    ladyfinger: { growingMonths: "April-August", season: "Kharif" },
    chili: { growingMonths: "January-May", season: "Zaid" },
    spinach: { growingMonths: "September-January", season: "Rabi" },
    radish: { growingMonths: "September-January", season: "Rabi" },
    bottlegourd: { growingMonths: "February-May", season: "Zaid" },
    mushroom: { growingMonths: "Throughout the year", season: "Perennial" },
    coriander: { growingMonths: "October-February", season: "Rabi" },
    jute: { growingMonths: "March-July", season: "Kharif" },
    BlackGram: { growingMonths: "June-October, October-March", season: "Kharif, Rabi" },
    lettuce: {  growingMonths: "October-March", season: "Rabi"},
    ginger: {"growingMonths": "March-August","season": "Kharif"},
    blackPepper: {"growingMonths": "June-February","season": "Perennial"}
  };

  const units = [
    "mg/kg", // Boron
    "mg/kg", // Cu
    "dS/m", // EC
    "mg/kg", // Fe
    "kg/ha", // K_Potassium
    "mg/kg", // Mn
    "kg/ha", // Nitrogen
    "w%", // OrganicCarbon
    "w%", // OrganicMatter
    "kg/ha", // Phosphorus
    "mg/kg", // Sulphur
    "mg/kg", // Zn
    "", // pH
    "", //SHI
    "", //Bulkdensity
  ];

  const classify = (value, range) => {
    if (!range) return 3;
    if (value < range[0]) return 1; // Low Deficient
    if (value > range[1]) return 0; // High
    return 3; // Medium or Neutral
  };
  const date = new Date(apiResponse.createdAt);
  const dateStr =
    date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
  const rangeStr = (range) => {
    var res = "range ";
    res = res + range[0] + " ";
    if (range[1] == Infinity) res = res + "<";
    else res = res + "- " + range[1];
    return res;
  };

  const cropRecommendations = apiResponse?.cropVegRecommendation
    ? JSON.parse(apiResponse.cropVegRecommendation)
    : [];

  // console.log(cropRecommendations)
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image
            style={styles.logo}
            src={soiltech} // Replace with your logo URL
          />
          <Text style={styles.title}>SOIL TEST REPORT</Text>
        </View>

        <View style={styles.container}>
          <View style={styles.leftSection}>
            <Text style={styles.sectionTitle}>Farmer Details</Text>
            <Text style={styles.sectionText}>ID: {apiResponse?._id}</Text>
            <Text style={styles.sectionText}>
              Name: {apiResponse?.username}
            </Text>
            <Text style={styles.sectionText}>
              Address: {apiResponse?.location}
            </Text>
            <Text style={styles.sectionText}>Phone: +91 790*****89</Text>
          </View>
          <View style={styles.rightSection}>
            <Text style={styles.sectionTitle}>Sample Information</Text>
            <Text style={styles.sectionText}>
              Agriculture Land Size: 1.1 Acre
            </Text>
            <Text style={styles.sectionText}>
              Location: {apiResponse?.villageCity}
            </Text>
            <Text style={styles.sectionText}>Survey No: 2324XXXXXX567</Text>
            <Text style={styles.sectionText}>
              Test Date:{" "}
              {(apiResponse?.testDate).match(/^\d{4}-\d{2}-\d{2}/)[0]}
            </Text>
          </View>
        </View>

        <View style={styles.table}>
          {Array.from({ length: 5 }).map((_, i) => (
            <View key={i} style={styles.tableRow}>
              {Array.from({ length: 3 }).map((_, j) => {
                const k = i * 3 + j;
                if (k > 13) return;
                const classfication = classify(
                  apiResponse[keys_array[k][1]],
                  ranges[keys_array[k][1]]
                );

                return (
                  <View key={k} style={styles.tableCol}>
                    <View style={styles.imageContainer}>
                      <Image
                        source={image_array[classfication]} // Replace with your image source
                        style={styles.image}
                      />
                    </View>
                    <View style={styles.detailsContainer}>
                      <Text style={styles.tableCell}>
                        {keys_array[k][0]}{" "}
                        {
                          <Text style={{ color: colors[classfication] }}>
                            {apiResponse[keys_array[k][1]]}
                          </Text>
                        }{" "}
                        {units[k]}
                      </Text>
                      <Text style={styles.tableCell}>
                        {rangeStr(ranges[keys_array[k][1]])}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
          ))}
        </View>

        <View style={styles.RecommendationBox}>
          <Text style={styles.RecommendationTitle}>Recommendation</Text>
          <View style={styles.rectableRow}>
            <Text style={styles.rectableHeader}>Crop Name</Text>
            <Text style={styles.rectableHeader}>Urea (kg/ha)</Text>
            <Text style={styles.rectableHeader}>Potash (kg/ha)</Text>
            <Text style={styles.rectableHeader}>DAP (kg/ha)</Text>
            <Text style={styles.rectableHeader}>Season</Text>
            <Text style={{...styles.rectableHeader,flex:'2'}}>Recommended Month</Text>
          </View>

          {/* Table Rows */}
          {cropRecommendations?.map((crop, index) => (
            <View style={styles.rectableRow} key={index}>
              <Text style={styles.rectableCell}>{crop[0]}</Text>
              <Text style={styles.rectableCell}>{crop[1]} kg/ha</Text>
              <Text style={styles.rectableCell}>{crop[2]} kg/ha</Text>
              <Text style={styles.rectableCell}>{crop[3]} kg/ha</Text>
              <Text style={styles.rectableCell}>
                {cropGrowingMonthsMap[crop[0].toLowerCase()]?.season}
              </Text>
              <Text style={{...styles.rectableCell,flex:'2'}}>
                {cropGrowingMonthsMap[crop[0].toLowerCase()]?.growingMonths}
              </Text>
            </View>
          ))}
        </View>
        {/* <View>{cropRecommendationViews}</View> */}

        <View style={styles.photoBox}>
          <Text style={styles.photoTitle}>Scaling</Text>

          <View style={styles.photoContainer}>
            {Array.from({ length: 4 }).map((_, index) => (
              <View key={index} style={styles.photoItem}>
                <Image
                  src={image_array[index]} // Replace with actual image source
                  style={styles.descImage}
                />
                <Text
                  style={{
                    fontSize: 10,
                    color: colors[index],
                    fontStyle: "italic",
                  }}
                >
                  {description_array[index]}
                </Text>
              </View>
            ))}
          </View>
        </View>

        <View style={{ marginTop: 5, padding: 10 }}>
          <Text style={{ fontSize: 12, color: "red", fontStyle: "italic" }}>
            NOTE: The fertilizer recommendation shown in this report is for a
            full season of the selected crop.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default Quixote;
