import React, { useEffect, useState, useRef } from "react";
import "./WeatherCard.css"; // Import the CSS file for styling
import { WiRain, WiDaySunny } from "react-icons/wi"; // Import icons from react-icons
import axios from "axios";

const WeatherCard = ({ weatherData }) => {
  const id = localStorage.getItem("GeneratedKey");
  const [focusedIndex, setFocusedIndex] = useState(null);
  const currentCardRef = useRef(null);
  const currentIndex = weatherData.findIndex((item) => item.isCurrent);
  const visibleForecast = weatherData.slice(
    Math.max(0, currentIndex - 3),
    Math.min(weatherData.length, currentIndex + 4)
  );

  // Focus on the current time item when component renders
  useEffect(() => {
    if (currentCardRef.current) {
      currentCardRef.current.focus(); // Set focus to current forecast card
    }
  }, [currentIndex]);
  return (
    <div className="weather-forecast-container">
      <div className="weather-forecast-heading">Weather Forecast</div>
      <div className="forecast-cards">
        {visibleForecast.map((item, index) => {
          const isCurrent = item.isCurrent;
          return (
            <div
              key={index}
              className={`forecast-card ${isCurrent ? "current" : ""}`}
              ref={isCurrent ? currentCardRef : null} // Ref to the current time card
              tabIndex={0} // Make the card focusable
            >
              <div className="time">{item.time}</div>
              <div className="icon">{item.icon}</div>
              {item.rainChance && (
                <div className="rain-chance">{item.rainChance}</div>
              )}
              <div className="temp">{item.temp}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WeatherCard;
