import React from 'react';
import { useEffect, useState } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

// import axios from 'axios';
// const userId = localStorage.getItem("GeneratedKey");
// const farmId=localStorage.getItem('FarmId');

const dummyData = {
  nitrogen: {
    month: [
      { month: 'JAN', value: 100 },
      { month: 'FEB', value: 250 },
      { month: 'MAR', value: 300 },
      { month: 'APR', value: 330 },
      { month: 'MAY', value: 440 },
      { month: 'JUNE', value: 120 },
      { month: 'JULY', value: 150 },
      { month: 'AUG', value: 160 },
      { month: 'SEP', value: 300 },
      { month: 'OCT', value: 230 },
      { month: 'NOV', value:300 },
      { month: 'DEC', value: 340 },
    ],
    week: [
      { week: 'Week 1', value: 180 },
      { week: 'Week 2', value: 200 },
      { week: 'Week 3', value: 250 },
      { week: 'Week 4', value: 300 },
    ],
    year: [
      { year: '2023', value: 300 },
      { year: '2024', value: 400 },
      { year: '2025', value: 500},
    ],
  },
  phosphorus: {
    month: [
      { month: 'JAN', value: 80 },
      { month: 'FEB', value: 120},
      { month: 'MAR', value: 90 },
      { month: 'APR', value: 130 },
      { month: 'MAY', value: 100 },
      { month: 'JUNE', value: 150 },
      { month: 'JULY', value: 180 },
      { month: 'AUG', value: 110 },
      { month: 'SEP', value: 160 },
      { month: 'OCT', value: 140 },
      { month: 'NOV', value: 170 },
      { month: 'DEC', value: 190 },
    ],
    week: [
      { week: 'Week 1', value: 400 },
      { week: 'Week 2', value: 300 },
      { week: 'Week 3', value: 500 },
      { week: 'Week 4', value: 600 },
    ],
    year: [
      { year: '2023', value: 250 },
      { year: '2024', value: 300 },
      { year: '2025', value: 350 },
    ],
  },
  potassium: {
    month: [
      { month: 'JAN', value: 70 },
      { month: 'FEB', value: 110 },
      { month: 'MAR', value: 60 },
      { month: 'APR', value: 90 },
      { month: 'MAY', value: 80 },
      { month: 'JUNE', value: 120 },
      { month: 'JULY', value: 150 },
      { month: 'AUG', value: 100 },
      { month: 'SEP', value: 130 },
      { month: 'OCT', value: 140 },
      { month: 'NOV', value: 160 },
      { month: 'DEC', value: 170 },
    ],
    week: [
      { week: 'Week 1', value: 350 },
      { week: 'Week 2', value: 400 },
      { week: 'Week 3', value: 450 },
      { week: 'Week 4', value: 500 },
    ],
    year: [
      { year: '2023', value: 220},
      { year: '2024', value: 270 },
      { year: '2025', value: 320 },
    ],
  },
};

const ChartComponent = ({ timeRange, selectedElement }) => {


  // const transformedData = {
  //   nitrogen: { month: [], week: [], year: [] },
  //   phosphorus: { month: [], week: [], year: [] },
  //   potassium: { month: [], week: [], year: [] }
  // };
  // console.log(transformedData)
  // // Helper function to get month, week, and year from the date
  // const extractDateInfo = (dateString) => {
  //   const date = new Date(dateString);
  //   const month = date.toLocaleString('default', { month: 'short' }).toUpperCase(); // Extract month in 'JAN', 'FEB' format
  //   const week = `Week ${Math.ceil(date.getDate() / 7)}`; // Determine the week based on the day of the month
  //   const year = date.getFullYear();
    
  //   return { month, week, year };
  // };
  

  // useEffect(()=>{
  //   const fetchSoilData = async () => {
  //     try {
  //       const response = await axios.post('https://soiltech.ai/api/selftest/graphdata',
  //         {userId:userId,farmId:farmId});
  //         const rawData=response.data.mydata;
  //         rawData.forEach(report => {
  //           const { month, week, year } = extractDateInfo(report.testDate);
          
  //           // Push data for each nutrient in month, week, and year
  //           transformedData.nitrogen.month.push({ month, value: report.Nitrogen });
  //           transformedData.nitrogen.week.push({ week, value: report.Nitrogen });
  //           transformedData.nitrogen.year.push({ year, value: report.Nitrogen });
          
  //           transformedData.phosphorus.month.push({ month, value: report.Phosphorus });
  //           transformedData.phosphorus.week.push({ week, value: report.Phosphorus });
  //           transformedData.phosphorus.year.push({ year, value: report.Phosphorus });
          
  //           transformedData.potassium.month.push({ month, value: report.K_Potassium });
  //           transformedData.potassium.week.push({ week, value: report.K_Potassium });
  //           transformedData.potassium.year.push({ year, value: report.K_Potassium });
  //         });
  //          } catch (err) {
  //       console.log(err)
  //     }
  //   };
  // fetchSoilData();  
  
  // },[farmId,userId])
  // Get data based on selected element and time range
  const data = dummyData[selectedElement][timeRange];

  // Determine the width for each time range for horizontal scroll
  const chartWidth = timeRange === 'month' ? 1500 : 1500;

  return (
    <div style={{ width: '100%', height: 300, overflowX: 'auto', WebkitOverflowScrolling: 'touch', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
      <div style={{ width: chartWidth, height: '100%' }}> {/* Make the inner div wider to allow scrolling */}
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={data}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="1 1" stroke="#e0e0e0" />
            <XAxis dataKey={timeRange === 'month' ? 'month' : (timeRange === 'week' ? 'week' : 'year')} />
            <YAxis hide />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="value"
              stroke="#8884d8"
              fill="url(#gradient)"
              fillOpacity={0.5}
            />
            <defs>
              <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.2} />
              </linearGradient>
            </defs>
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ChartComponent;


