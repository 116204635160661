import React from 'react';
import './CustomModal.css'; // Optional: Create and style your modal

const CustomModal = ({ show, handleClose, message }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {message}
        <div className="modal-button">
          <button className="modal-close-btn" onClick={handleClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
