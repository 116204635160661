import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Sidebar from "../../components/Sidebar/Sidebar";
import DHeader from "../../components/DashboardHeader/DHeader";
import RiceCrop from "../../assets/image/ricecrop.png";
import WheatCrop from "../../assets/image/wheat.png";
import SorghumCrop from "../../assets/image/sorghum.png";
import MilletCrop from "../../assets/image/millets.png";
import BarleyCrop from "../../assets/image/Barley.png";
import CocunutCrop from "../../assets/image/Coconut 1.png";
import CoffeeCrop from "../../assets/image/Coffee 1.png";
import CottonCrop from "../../assets/image/Cotton.png";
import CowBeansCrop from "../../assets/image/Cow Beans.png";
import CucumberCrop from "../../assets/image/Cucumber.png";
import GramCrop from "../../assets/image/gram 1.png";
import GroundnutCrop from "../../assets/image/Groundnut 1.png";
import LentilCrop from "../../assets/image/lentil 1.png";
import MaizeCrop from "../../assets/image/Maize.png";
import MuskmelonCrop from "../../assets/image/Muskmelon 1.png";
import MustardCrop from "../../assets/image/Mustrad.png";
import PeasCrop from "../../assets/image/Peas.png";
import PumpkinCrop from "../../assets/image/Pumpkin 1.png";
import RubberCrop from "../../assets/image/RUBBER_PLANTATION 1.png";
import SoyabeanCrop from "../../assets/image/Soyabean 1.png";
import SugarcaneCrop from "../../assets/image/Sugarcane 1.png";
import SummerMaizeCrop from "../../assets/image/Summer Maize 1.png";
import TobaccoCrop from "../../assets/image/Tobacco 1.png";
import TurCrop from "../../assets/image/Tur  1.png";
import WaterMelonCrop from "../../assets/image/WaterMelon.png";
import BitterGourdCrop from "../../assets/image/BitterGourd.png";
import BottleGourdCrop from "../../assets/image/BottleGourd.png";
import BrinjalCrop from "../../assets/image/Brinjal.png";
import CabbageCrop from "../../assets/image/Brinjal.png";
import CarrotCrop from "../../assets/image/Carrot.png";
import CauliFlowerCrop from "../../assets/image/CauliFlower.png";
import ChilliCrop from "../../assets/image/Chilli.png";
import CorainderCrop from "../../assets/image/Corainder.png";
import LadyFingerCrop from "../../assets/image/LadyFinger.png";
import MushroomCrop from "../../assets/image/Mushroom.png";
import OnionCrop from "../../assets/image/Onion.png";
import PotatoCrop from "../../assets/image/Potato.png";
import RadishCrop from "../../assets/image/Radish.png";
import SpinchCrop from "../../assets/image/Spinch.png";
import TomatoCrop from "../../assets/image/Tomato.png";
import DummyImageCrop from "../../assets/image/Dummy Image 1.png";



import Recommendationbackground from "../../assets/image/recommendationbackground.png";
import RecommendationBackgroundMobile from "../../assets/images/RBackgroundMo.png";
import enctick from "../../assets/images/encircled_tick.png";
import notick from "../../assets/images/notick.png";
import WeatherForecast from "../../components/WeatherForecast/WeatherForecast";

import "./Recommendation.css";

const cropImageMap = {
  "rice": RiceCrop,
  "wheat": WheatCrop,
  "sorghum": SorghumCrop,
  "millet": MilletCrop,
  "barley": BarleyCrop,
  "bitterGourd": BitterGourdCrop,
  "cocunut": CocunutCrop,
  "coffee": CoffeeCrop,
  "cotton": CottonCrop,
  "cowBeans": CowBeansCrop,
  "cucumber": CucumberCrop,
  "gram": GramCrop,
  "Groundnut": GroundnutCrop,
  "lentil": LentilCrop,
  "maize": MaizeCrop,
  "muskmelon": MuskmelonCrop,
  "mustard": MustardCrop,
  "pea": PeasCrop,
  "pumpkin": PumpkinCrop,
  "rubber": RubberCrop,
  "soybean": SoyabeanCrop,
  "sugarcane": SugarcaneCrop,
  "summerMaize": SummerMaizeCrop,
  "tobacco": TobaccoCrop,
  "tur": TurCrop,
  "waterMelon": WaterMelonCrop,
  "bottlegourd": BottleGourdCrop,
  "brinjal": BrinjalCrop,
  "cabbage": CabbageCrop,
  "carrot": CarrotCrop,
  "cauliflower": CauliFlowerCrop,
  "chilli": ChilliCrop,
  "corainder": CorainderCrop,
  "ladyfinger": LadyFingerCrop,
  "mushroom": MushroomCrop,
  "onion": OnionCrop,
  "potato": PotatoCrop,
  "radish": RadishCrop,
  "spinach": SpinchCrop,
  "tomato": TomatoCrop,
   "DummyImage":DummyImageCrop,
  
};

function Recommendation() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const recommendation = queryParams.get("recommendation");
  const seedingDate = queryParams.get("seedingDate");
  const currentCrop=queryParams.get("currentcrop");
  const city=queryParams.get("city");
  const state=queryParams.get("state")
  console.log( 'recieved',recommendation,seedingDate,currentCrop)
  // Mock Data for Crops with Images
  const [isMobile, setIsMobile] = useState(false);
  const [cropsDataRecommended,setCropsDataRecommended]=useState([]);
  const [cropsData, setCropsData] = useState({
    cropName: '',
    imageUrl: '',
    dosage: {
      Urea: 0,
      Potash: 0,
      DAP: 0,
    },
  });
  const today = new Date();
  const seeding = seedingDate ? new Date(seedingDate) : today;
  const diffInMs = today - seeding;
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  const cropGrowingMonthsMap = {
    "wheat": { growingMonths: "November-March", season: "Rabi" },
    "barley": { growingMonths: "November-March", season: "Rabi" },
    "mustard": { growingMonths: "October-January", season: "Rabi" },
    "peas": { growingMonths: "October-February", season: "Rabi" },
    "gram": { growingMonths: "October-January", season: "Rabi" },
    "lentils": { growingMonths: "October-January", season: "Rabi" },
    "rice": { growingMonths: "June-October", season: "Kharif" },
    "maize": { growingMonths: "June-October", season: "Kharif" },
    "cotton": { growingMonths: "April-August", season: "Kharif" },
    "sorghum": { growingMonths: "July-October", season: "Kharif" },
    "bajra": { growingMonths: "June-September", season: "Kharif" },
    "pea": { growingMonths: "June-October", season: "Kharif" },
    "groundnut": { growingMonths: "June-September", season: "Kharif" },
    "soybean": { growingMonths: "June-September", season: "Kharif" },
    "watermelon": { growingMonths: "February-May", season: "Zaid" },
    "cucumber": { growingMonths: "February-May", season: "Zaid" },
    "muskmelon": { growingMonths: "February-May", season: "Zaid" },
    "bittergourd": { growingMonths: "March-June", season: "Zaid" },
    "pumpkin": { growingMonths: "February-May", season: "Zaid" },
    "summermaize": { growingMonths: "March-June", season: "Zaid" },
    "cowpea": { growingMonths: "April-July", season: "Zaid" },
    "tea": { growingMonths: "Throughout the year", season: "Perennial" },
    "coffee": { growingMonths: "Throughout the year", season: "Perennial" },
    "rubber": { growingMonths: "Throughout the year", season: "Perennial" },
    "tobacco": { growingMonths: "October-January", season: "Rabi" },
    "sugarcane": { growingMonths: "October-February", season: "Perennial" },
    "coconut": { growingMonths: "Throughout the year", season: "Perennial" },
    "tomato": { growingMonths: "January-April, September-October", season: "Zaid" },
    "potato": { growingMonths: "October-January", season: "Rabi" },
    "onion": { growingMonths: "October-February", season: "Rabi" },
    "brinjal": { growingMonths: "January-July", season: "Zaid" },
    "cabbage": { growingMonths: "October-February", season: "Rabi" },
    "cauliflower": { growingMonths: "October-January", season: "Rabi" },
    "carrot": { growingMonths: "October-January", season: "Rabi" },
    "ladyfinger": { growingMonths: "April-August", season: "Kharif" },
    "chili": { growingMonths: "January-May", season: "Zaid" },
    "spinach": { growingMonths: "September-January", season: "Rabi" },
    "radish": { growingMonths: "September-January", season: "Rabi" },
    "bottlegourd": { growingMonths: "February-May", season: "Zaid" },
    "mushroom": { growingMonths: "Throughout the year", season: "Perennial" },
    "coriander": { growingMonths: "October-February", season: "Rabi" },
    "jute": { growingMonths: "March-July", season: "Kharif" },
    "Black Gram": { growingMonths: "June-October, October-March", season: "Kharif, Rabi" },
    "mustardGreen": { growingMonths: "October-February", season: "Rabi" },
    "lettuce": {  growingMonths: "October-March", season: "Rabi"},
    "ginger": {growingMonths: "March-August",season: "Kharif"},
    "BlackPepper": {growingMonths: "June-February",season: "Perennial"}
  };
  

  const reformatCropsData = (recommendation) => {
    try{
    const crops = recommendation.split(','); // Split the input data by commas
    const reformattedCropsData = [];
  
    for (let i = 0; i < crops.length; i += 4) {
      reformattedCropsData.push({
        cropName: crops[i],
        imageUrl: cropImageMap[crops[i].toLowerCase()] || DummyImageCrop,
        cropGrowingMonths:cropGrowingMonthsMap[crops[i].toLowerCase()],

         // Use mapping or a default image if not found
        dosage: {
          Urea: parseInt(crops[i + 1], 10),   // Convert string to number
          Potash: parseInt(crops[i + 2], 10),
          DAP: parseInt(crops[i + 3], 10),
        },
      });
    }
  
    return reformattedCropsData; }

    catch(error){
      console.log(error)
    }
    // Return the formatted data
  };
// console.log('after reformatting the data is',reformatCropsData(recommendation))  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const reformattedData = reformatCropsData(recommendation);
    setCropsDataRecommended(reformattedData);

    
  }, [recommendation]);


  const cropFertilizerMap = {
    "wheat": { Urea: 120, Potash: 40, DAP: 80 },
    "barley": { Urea: 100, Potash: 30, DAP: 75 },
    "mustard": { Urea: 90, Potash: 20, DAP: 60 },
    "peas": { Urea: 50, Potash: 40, DAP: 100 },
    "gram": { Urea: 55, Potash: 30, DAP: 70 },
    "lentils": { Urea: 60, Potash: 25, DAP: 65 },
    "rice": { Urea: 110, Potash: 60, DAP: 75 },
    "maize": { Urea: 120, Potash: 50, DAP: 80 },
    "cotton": { Urea: 150, Potash: 50, DAP: 85 },
    "sorghum ": { Urea: 100, Potash: 45, DAP: 70 },
    "bajra": { Urea: 90, Potash: 40, DAP: 65 },
    "pea": { Urea: 80, Potash: 35, DAP: 60 },
    "groundnut": { Urea: 50, Potash: 50, DAP: 100 },
    "soybean": { Urea: 80, Potash: 60, DAP: 90 },
    "watermelon": { Urea: 100, Potash: 80, DAP: 60 },
    "cucumber": { Urea: 90, Potash: 70, DAP: 65 },
    "muskmelon": { Urea: 85, Potash: 65, DAP: 55 },
    "bittergourd": { Urea: 80, Potash: 60, DAP: 50 },
    "pumpkin": { Urea: 100, Potash: 75, DAP: 70 },
    "maize": { Urea: 120, Potash: 50, DAP: 80 },
    "cowpea": { Urea: 70, Potash: 30, DAP: 60 },
    "tea": { Urea: 200, Potash: 100, DAP: 80 },
    "coffee": { Urea: 180, Potash: 80, DAP: 75 },
    "rubber": { Urea: 150, Potash: 60, DAP: 85 },
    "tobacco": { Urea: 120, Potash: 50, DAP: 70 },
    "sugarcane": { Urea: 250, Potash: 100, DAP: 100 },
    "coconut": { Urea: 300, Potash: 150, DAP: 125 },
    "tomato": { Urea: 90, Potash: 50, DAP: 70 },
    "potato": { Urea: 110, Potash: 60, DAP: 80 },
    "onion": { Urea: 80, Potash: 50, DAP: 60 },
    "brinjal": { Urea: 85, Potash: 45, DAP: 65 },
    "cabbage": { Urea: 100, Potash: 70, DAP: 60 },
    "cauliflower": { Urea: 90, Potash: 65, DAP: 55 },
    "carrot": { Urea: 60, Potash: 40, DAP: 50 },
    "ladyfinger": { Urea: 70, Potash: 35, DAP: 60 },
    "chili": { Urea: 80, Potash: 45, DAP: 55 },
    "spinach": { Urea: 50, Potash: 30, DAP: 40 },
    "radish": { Urea: 60, Potash: 40, DAP: 50 },
    "bottlegourd": { Urea: 90, Potash: 60, DAP: 70 },
    "mushroom": { Urea: 40, Potash: 20, DAP: 30 },
    "coriander": { Urea: 30, Potash: 20, DAP: 25 },
  };




  useEffect(() => {
    if (currentCrop) {
      const dosage = cropFertilizerMap[currentCrop.toLowerCase()] || { Urea: 25, Potash: 25, DAP: 50 };
      setCropsData({
        cropName: currentCrop,
        imageUrl: cropImageMap[currentCrop.toLowerCase()] || DummyImageCrop,
        dosage: dosage
      });
    }
  }, [currentCrop]);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600); // Mobile if screen width <= 768px
  };

  useEffect(() => {
    handleResize(); // Set initial state
    window.addEventListener("resize", handleResize); // Add event listener for resizing
    return () => window.removeEventListener("resize", handleResize); // Clean up listener
  }, []);

  return (
    <div className="Recommendation-container">
      {/* Sidebar Section */}
      <div className="Recommendation-container1">
        <Sidebar />
      </div>

      {/* Main Content Section */}
      <div className="Recommendation-container2">
        <DHeader isMobile={isMobile} />
        <div className="Recommendation-maincontent">
          {/* Top Section */}
         {isMobile?(<>
         <div className="recommendation-top-mobile">
          <div
            className="recommendation-top"
            style={{ backgroundImage: `url(${Recommendationbackground})` }}
          >
            <div className="date-weather">
              {/* <h2 className="date-weather-h2">Monday</h2>
              <p className="date-weather-p">21st Aug 2024 | Menal, Rajasthan</p>
              <p className="date-weather-p">02:40 PM | Sunny</p> */}
                <WeatherForecast city={city} state={state} />
            </div>
            </div>
            <div className="crop-stages-info">
              <div className="lower-half">
                <div
                  className="lower-half-row js-se"
                  style={{ height: "16.8vh" }}
                >
                  <div
                    className="column_cropstages aic wfc"
                    style={{ position: "relative", marginTop: "10px" }}
                  >
                    <img
                      src={diffInDays>1 ?enctick: notick}
                      className="fs-small"
                      alt="tick or no-tick"
                    />
                    <div
                      style={{
                        position: "absolute",
                        fontSize: "10px",
                        top: "2.5rem",
                        textAlign: "center",
                        color:"#FFF;"
                      }}
                    >
                      <b>Seeding</b>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        fontSize: "10px",
                        top: "4.5rem",
                        textAlign: "center",
                      }}
                    >
                      Day
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        fontSize: "10px",
                        top: "5.5rem",
                        textAlign: "center",
                        width: "15vw",
                      }}
                    >
                      0-10
                    </div>
                  </div>

                  <div className="line"></div>

                  <div
                    className="column_cropstages aic wfc"
                    style={{ position: "relative", marginTop: "10px" }}
                  >
                    <img
                      src={diffInDays>=15 ? enctick:notick}
                      className="fs-small"
                      alt="tick or no-tick"
                    />
                    <div
                      style={{
                        position: "absolute",
                        fontSize: "10px",
                        top: "2.5rem",
                        textAlign: "center",
                      }}
                    >
                      <b>Fertilizer Application</b>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        fontSize: "10px",
                        top: "4.5rem",
                        textAlign: "center",
                      }}
                    >
                      Day
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        fontSize: "10px",
                        top: "5.5rem",
                        textAlign: "center",
                        width: "15vw",
                      }}
                    >
                      15-20
                    </div>
                  </div>

                  <div className="line"></div>
                  <div
                    className="column_cropstages aic wfc"
                    style={{ position: "relative", marginTop: "10px" }}
                  >
                    <img
                      src={diffInDays>=30 ? enctick : notick}
                      className="fs-small"
                      alt="tick or no-tick"
                    />
                    <div
                      style={{
                        position: "absolute",
                        fontSize: "10px",
                        top: "2.5rem",
                        textAlign: "center",
                      }}
                    >
                      <b>Irrigation</b>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        fontSize: "10px",
                        top: "4.5rem",
                        textAlign: "center",
                      }}
                    >
                      Day
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        fontSize: "10px",
                        top: "5.5rem",
                        textAlign: "center",
                        width: "15vw",
                      }}
                    >
                      30-35{" "}
                    </div>
                  </div>
                  <div className="line"></div>

                  <div
                    className="column_cropstages aic wfc"
                    style={{ position: "relative", marginTop: "10px" }}
                  >
                    <img
                      src={diffInDays>=40 ?enctick :notick}
                      className="fs-small"
                      alt="tick or no-tick"
                    />
                    <div
                      style={{
                        position: "absolute",
                        fontSize: "10px",
                        top: "2.5rem",
                        textAlign: "center",
                      }}
                    >
                      <b>Apply Pesticides</b>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        fontSize: "10px",
                        top: "4.5rem",
                        textAlign: "center",
                      }}
                    >
                      Day
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        fontSize: "10px",
                        top: "5.5rem",
                        textAlign: "center",
                        width: "15vw",
                      }}
                    >
                      40-45
                    </div>
                  </div>

                  <div className="line"></div>

                  <div
                    className="column_cropstages aic wfc"
                    style={{ position: "relative", marginTop: "10px" }}
                  >
                    <img
                      src={diffInDays>=60 ? enctick: notick}
                      className="fs-small"
                      alt="tick or no-tick"
                    />
                    <div
                      style={{
                        position: "absolute",
                        fontSize: "10px",
                        top: "2.5rem",
                        textAlign: "center",
                      }}
                    >
                      <b>Harvesting</b>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        fontSize: "10px",
                        top: "4.5rem",
                        textAlign: "center",
                      }}
                    >
                      Day
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        fontSize: "10px",
                        top: "5.5rem",
                        textAlign: "center",
                        width: "15vw",
                      }}
                    >
                      60-70{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="info">
                <p>
                  These crops are typically sown at the beginning of the monsoon
                  season and harvested at the end. They require substantial
                  rainfall.
                </p>
              </div>
            </div>
            </div>
            </>
          
         ):(<>
         <div
          className="recommendation-top"
          style={{ backgroundImage: `url(${Recommendationbackground})` }}
        >
          <div className="date-weather">
            {/* <h2 className="date-weather-h2">Monday</h2>
            <p className="date-weather-p">21st Aug 2024 | Menal, Rajasthan</p>
            <p className="date-weather-p">02:40 PM | Sunny</p> */}
            <WeatherForecast city={city} state={state} />
          </div>
        
          <div className="crop-stages-info">
            <div className="lower-half">
              <div
                className="lower-half-row js-se"
                style={{ height: "16.8vh" }}
              >
                <div
                  className="column_cropstages aic wfc"
                  style={{ position: "relative", marginTop: "10px" }}
                >
                  <img
                    src={diffInDays>1? enctick:notick}
                    className="fs-small"
                    alt="tick or no-tick"
                  />
                  <div
                    style={{
                      position: "absolute",
                      fontSize: "10px",
                      top: "2.5rem",
                      textAlign: "center",
                    }}
                  >
                    <b>Seeding</b>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      fontSize: "10px",
                      top: "4.5rem",
                      textAlign: "center",
                    }}
                  >
                    Day
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      fontSize: "10px",
                      top: "5.5rem",
                      textAlign: "center",
                      width: "15vw",
                    }}
                  >
                    0-10
                  </div>
                </div>

                <div className="line"></div>

                <div
                  className="column_cropstages aic wfc"
                  style={{ position: "relative", marginTop: "10px" }}
                >
                  <img
                    src={diffInDays>=15?enctick:notick}
                    className="fs-small"
                    alt="tick or no-tick"
                  />
                  <div
                    style={{
                      position: "absolute",
                      fontSize: "10px",
                      top: "2.5rem",
                      textAlign: "center",
                    }}
                  >
                    <b>Fertilizer Application</b>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      fontSize: "10px",
                      top: "4.5rem",
                      textAlign: "center",
                    }}
                  >
                    Day
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      fontSize: "10px",
                      top: "5.5rem",
                      textAlign: "center",
                      width: "15vw",
                    }}
                  >
                    15-20
                  </div>
                </div>

                <div className="line"></div>
                <div
                  className="column_cropstages aic wfc"
                  style={{ position: "relative", marginTop: "10px" }}
                >
                  <img
                    src={diffInDays>=30?enctick:notick}
                    className="fs-small"
                    alt="tick or no-tick"
                  />
                  <div
                    style={{
                      position: "absolute",
                      fontSize: "10px",
                      top: "2.5rem",
                      textAlign: "center",
                    }}
                  >
                    <b>Irrigation</b>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      fontSize: "10px",
                      top: "4.5rem",
                      textAlign: "center",
                    }}
                  >
                    Day
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      fontSize: "10px",
                      top: "5.5rem",
                      textAlign: "center",
                      width: "15vw",
                    }}
                  >
                    30-35{" "}
                  </div>
                </div>
                <div className="line"></div>

                <div
                  className="column_cropstages aic wfc"
                  style={{ position: "relative", marginTop: "10px" }}
                >
                  <img
                    src={diffInDays>=30?enctick:notick}
                    className="fs-small"
                    alt="tick or no-tick"
                  />
                  <div
                    style={{
                      position: "absolute",
                      fontSize: "10px",
                      top: "2.5rem",
                      textAlign: "center",
                    }}
                  >
                    <b>Apply Pesticides</b>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      fontSize: "10px",
                      top: "4.5rem",
                      textAlign: "center",
                    }}
                  >
                    Day
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      fontSize: "10px",
                      top: "5.5rem",
                      textAlign: "center",
                      width: "15vw",
                    }}
                  >
                    40-45
                  </div>
                </div>

                <div className="line"></div>

                <div
                  className="column_cropstages aic wfc"
                  style={{ position: "relative", marginTop: "10px" }}
                >
                  <img
                    src={diffInDays>=60?enctick:notick}
                    className="fs-small"
                    alt="tick or no-tick"
                  />
                  <div
                    style={{
                      position: "absolute",
                      fontSize: "10px",
                      top: "2.5rem",
                      textAlign: "center",
                    }}
                  >
                    <b>Harvesting</b>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      fontSize: "10px",
                      top: "4.5rem",
                      textAlign: "center",
                    }}
                  >
                    Day
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      fontSize: "10px",
                      top: "5.5rem",
                      textAlign: "center",
                      width: "15vw",
                    }}
                  >
                    60-70{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="info">
              <p>
                These crops are typically sown at the beginning of the monsoon
                season and harvested at the end. They require substantial
                rainfall.
              </p>
            </div>
          </div>
          </div>
          </>
        )}
          <div className="Recommendation-plantedcrop">
            <h4 style={{ padding: "1vw" }}>Current Planted Crops</h4>
             {cropsData?(
              <div className="crop-list">
                  <div className="crop-card">
                    <img
                      src={cropsData.imageUrl}
                      alt={cropsData.cropName}
                      className="crop-image"
                    />
                    <div>
                      <h5
                        style={{
                          // display: "flex",
                          // alignItems: "flex-start",
                          // padding: "0 0 0 1vw",
                          color: '#ff9933', textAlign: 'center', margin: '10px 0' 
                        }}
                      >
                        {cropsData.cropName}(Current Crop)
                      </h5>
                      <p
                        style={{
                          color: "#129172",
                          textAlign: 'center', margin: '10px 0' 
                      
                        }}
                      >
                        Crop Fertilizer Dosage
                      </p>
                      <div className="dosage-details">
                        <div className="dosage-details-left">
                          <p className="dosage-details-left-p">
                            Urea: {cropsData.dosage.Urea} kg/ha
                          </p>
                          <p className="dosage-details-left-p">
                            Potash: {cropsData.dosage.Potash} kg/ha
                          </p>
                          <p className="dosage-details-left-p">
                            DAP: {cropsData.dosage.DAP} kg/ha
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
             ):(<p>no data available</p>)}
          </div>

          {/* Crop Recommendations Section */}
          <div className="Recommendation-crop">
            <h4>Crop Recommendations</h4>
              {cropsDataRecommended ? (
              <div className="crop-list-container">
              <div className="crop-list">
                {cropsDataRecommended.map((crop, index) => (
                  <div key={index} className="crop-card">
                    <img
                      src={crop?.imageUrl}
                      alt={crop?.cropName}
                      className="crop-image"
                    />
                    <h5 style={{ color: '#ff9933', textAlign: 'center', margin: '10px 0' }}>{crop?.cropName} Crop (Recommended)</h5>
                    <div className="Recommendation-month-season">
                    <div className="Recommendation-month">
                    <span>
                      {crop?.cropGrowingMonths?.growingMonths}  
                      </span>
                    </div>
                    <div className="Recommendation-season">
                      <span>
                       Season:{crop?.cropGrowingMonths?.season}
                      </span>
                    </div>
                    </div>
                   

                    <div className="dosage-details no-dots">
                    <div className="dosage-details-left">
                          <p className="dosage-details-left-p">
                            Urea: {crop.dosage.Urea} kg/ha
                          </p>
                          <p className="dosage-details-left-p">
                            Potash: {crop.dosage.Potash} kg/ha
                          </p>
                          <p className="dosage-details-left-p">
                            DAP: {crop.dosage.DAP} kg/ha
                          </p>
                        </div>
                    </div>
                  </div>
                ))}
              </div>
              </div>

              ):(<p>no data available</p>)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Recommendation;
