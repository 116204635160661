

import React from 'react';
import GaugeChart from 'react-gauge-chart';

const GaugeChartDashboard = ({soilshi}) => {
  const soilIndVal = (typeof soilshi === 'number' && !isNaN(soilshi)) ? soilshi.toFixed(2) : 'No Data available';
  const getSoilHealthCategory = (value) => {
    if (value > 0.90) return 'Excellent';
    if (value > 0.75) return 'Very Good';
    if (value > 0.50) return 'Good';
    if (value > 0.25) return 'Poor';
    return 'Very Poor';
  };

  // Determine the category based on soilshi (which is the index value)
  const soilCategory = getSoilHealthCategory(soilIndVal);
  return (
    <div style={{ width: '200px' }}>
      <GaugeChart
        id="soil-health-chart"
        nrOfLevels={20} // Sets the number of segments
        arcsLength={[0.25, 0.25, 0.25, 0.15, 0.1]} // Adjust these based on your thresholds
        colors={['#FF5F6D', '#FFC371', '#FFE700', '#A8E063', '#00E396']} // Red to green color range
        percent={soilshi} // Adjust this dynamically based on soil health data (example is 0.45 for 'Average')
        arcPadding={0.02} // Padding between the arcs
        textColor="#000000"
        hideText={true}
      />
      <div style={{ textAlign: 'center', fontSize: '16px', color: '#F5D169' }}>
        Soil Health Index - {soilIndVal} ({soilCategory})
      </div>
    </div>
  );
};

export default GaugeChartDashboard;
  