import React, { useState, useEffect } from "react";
import "./DHeader.css";
import farmer from "../../assets/image/farmer_image.png";
import { FaBars, FaBell } from "react-icons/fa"; // Notification icon
import Dropdown from "../../assets/icons/dropdown.svg";
import Sidebar from "../Sidebar/Sidebar";
import Hambager_out from "../../assets/icons/hambager_out.png";
import { useNavigate, useLocation } from "react-router-dom";
const dummyFarms = [
  { name: "Farm1", id: "abc458" },
  { name: "Farm2", id: "cbz765" },
  { name: "Farm3", id: "ktm928" },
  { name: "Farm4", id: "r15abz" },
  { name: "Farm5", id: "xcd130" },
];

const DHeader = ({ isMobile }) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [language, setLanguage] = useState("English");
  const [selectedLanguage, setSelectedLanguage] = useState("Language");
  const [farmOpen, setFarmOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const navigate = useNavigate();
  const username = localStorage.getItem("username");
  const location = useLocation();
  // Dummy farm data
  const [selectedFarm, setSelectedFarm] = useState(dummyFarms[0].name);
  const currentId = localStorage.getItem("FarmId");
  const setid = currentId ? currentId : dummyFarms[0].id;
  const [selectedFarmId, setSelectedFarmId] = useState(setid);
  const savedImage = localStorage.getItem("profileImage");
  useEffect(() => {
    // This effect runs whenever selectedFarmId changes
    if (selectedFarmId) {
      localStorage.setItem("FarmId", selectedFarmId);
      window.dispatchEvent(new Event("storageChange"));
      setSelectedFarm(dummyFarms[selectedFarmId]);
    }
  }, [selectedFarmId]);

  useEffect(() => {
    const fetchFormId = () => {
      const id = localStorage.getItem("FarmId");
      setSelectedFarmId(id);
    };

    window.addEventListener("formIdFromAddFarm", fetchFormId);
    return () => {
      window.removeEventListener("formIdFromAddFarm", fetchFormId);
    };
  }, []);

  // Handle clicking outside to close the dropdown

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  const handleImageClick = () => {
    navigate("/editprofile");
  };
  const handleFarmChange = (event) => {
    const selectedId = event.target.value; // Get the selected farm ID
    setSelectedFarmId(selectedId);

    const Farm = dummyFarms.find((farm) => farm.id === selectedId);
    if (Farm) {
      setSelectedFarm(Farm.name); // Set crossPending based on the selected farm's name
    }

    // Update the selected farm ID
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".dropdownButton") &&
        !event.target.closest(".dropdownMenu")
      ) {
        setLanguage(false);
        setFarmOpen(false);
        setNotificationOpen(false);
        setProfileOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    // return () => {
    //   document.removeEventListener("mousedown", handleClickOutside);
    // };
  }, []);

  // Handle language selection
  // const handleLanguageSelect = (language) => {
  //   setSelectedLanguage(language);
  //   setLanguage(false);

  // };
  // const handleFarmSelect = (farm) => {
  //   setSelectedFarm(farm);
  //   setFarmOpen(false);
  // };

  if (isMobile) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <header className="header">
          {/* Mobile View - Hamburger Menu */}
          {/* Mobile View - Hamburger Menu */}
          <div className="mobile-header">
            <img
              src={Hambager_out}
              alt="Hambager"
              onClick={toggleMobileMenu}
              style={{ width: "30px", height: "30px" }}
            />
          </div>

          {/* Sidebar for mobile */}
          {isMobileMenuOpen && (
            <Sidebar
              isMobileView={isMobile}
              onClose={() => setMobileMenuOpen(false)} // Prop to handle sidebar close
            />
          )}

          <div className="headerButtons">
            {/* Notification Icon */}

            <div className="icon-button">
              <FaBell size={24} />
            </div>

            {/* <img
              src={savedImage}
              alt="Profile"
              className="profile-image"
              onClick={handleImageClick}
            /> */}
            <div className="header-profile-image-wrapper">
            <img
              src={savedImage}
              alt="Profile"
              className="header-profile-image"
              onClick={handleImageClick} // Add your edit profile click handler
            />
          </div>
          </div>
        </header>
        <div style={{ padding: "10px" }}>
          <select
            className="farm-dropdown"
            value={selectedFarmId} // Bind the selected value to farm ID
            onChange={handleFarmChange}
          >
            <option value="">Select a farm</option> {/* Default empty option */}
            {dummyFarms.map((farm, index) => (
              <option key={index} value={farm.id}>
                {" "}
                {/* Use farm.id as value */}
                {farm.name} {/* Display only the farm name */}
              </option>
            ))}
          </select>
        </div>
        <div className="welcome-section">
          <h1 className="welcome-text">Welcome {username}</h1>
          <p className="subtext">
            Take a soil test, view reports, and get AI-powered crop suggestions
            tailored to your farm.
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <header className="header">
        {/* Desktop View */}
        <div className="welcome-section">
          <h1 className="welcome-text">Welcome {username}</h1>
          <p className="subtext">
            Take a soil test, view reports, and get AI-powered crop suggestions
            tailored to your farm.
          </p>
        </div>

        <div className="headerButtons">
          <select
            className="farm-dropdown"
            value={selectedFarmId} // Bind the selected value to farm ID
            onChange={handleFarmChange}
          >
            <option value="" hidden>Select a farm</option> {/* Default empty option */}
            {dummyFarms.map((farm, index) => (
              <option key={index} value={farm.id}>
                {" "}
                {/* Use farm.id as value */}
                {farm.name} {/* Display only the farm name */}
              </option>
            ))}
          </select>
          {/* Notification Icon */}
          <div className="icon-button">
            <FaBell size={24} />
          </div>

          {/* <img
            src={savedImage}
            alt="Profile"
            className="profile-image"
            onClick={handleImageClick}
          /> */}
          <div className="header-profile-image-wrapper">
            <img
              src={savedImage}
              alt="Profile"
              className="header-profile-image"
              onClick={handleImageClick} // Add your edit profile click handler
            />
          </div>
        </div>
      </header>
    );
  }
};

export default DHeader;
