// src/components/WeatherForecast.js
import React,{useState,useEffect} from 'react';
import { FaSun } from 'react-icons/fa';
import { Box, Grid, Typography } from '@mui/material';
import './weatherForecast.css'
import sun from '../../assets/images/sun_holo.png'
import loc from '../../assets/images/location.png'

const WeatherForecast = ({city,state}) => {

  const [currentDateTime, setCurrentDateTime] = useState('');

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();

      // Format date and time
      const optionsDate = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
      const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true };

      // Get full date and time
      const formattedDate = now.toLocaleDateString('en-US', optionsDate);
      const formattedTime = now.toLocaleTimeString('en-US', optionsTime);

      // Extract parts from formattedDate
      const [dayOfWeek,month,dayNumber, year] = formattedDate.split(' ');

      // Function to get ordinal suffix
      const ordinalSuffix = (dayNumber) => {
        const dayNum = parseInt(dayNumber, 10);
        if (dayNum > 3 && dayNum < 21) return 'th'; // 11-13
        switch (dayNum % 10) {
          case 1: return 'st';
          case 2: return 'nd';
          case 3: return 'rd';
          default: return 'th';
        }
      };

      // Combine parts to create formatted date
      const formattedDateWithSuffix = `${parseInt(dayNumber)}${ordinalSuffix(dayNumber)} ${month}, ${year}`;

      // Set state
      setCurrentDateTime({
        day: dayOfWeek,
        date: formattedDateWithSuffix,
        time: formattedTime
      });
    };

    updateDateTime(); // Initial call
    const intervalId = setInterval(updateDateTime, 30000); // Update every minute

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

//  console.log(currentDateTime['day'])
  return (
    <div className='column_weather_forecast'>
      <div className='upper-container'>
        <div className='day-heading'>{currentDateTime['day']}</div>
        <div className='date'>{currentDateTime['date']}</div>
        <div className='location'><img src={loc} style={{ height: "20px"}}/>{city} , {state}</div>
      </div>
      <div className='lower-container'>
        <img src={sun}/>
        <div className='time'>{currentDateTime['time']}</div>
        {/* <div className='weather-type'>Sunny</div> */}
      </div>
    </div>
  );
};

export default WeatherForecast;
