import React from 'react';
import './SoilAnalysis.css'; // You can style it here

const SoilAnalysis = ({ data }) => {
  return (
    
    <div className="soil-analysis">
      <div className='soil-analysis-heading'>Soil Analysis (per hectare)</div>
      {data && data.length > 0 ? (
      data.map((item, index) => (
        <div key={index} className="soil-analysis-row">
          <div className="label-circle" style={{ backgroundColor: item.color }}>
            {item.label}
          </div>
          <div className="progress-bar">
            <div className="progress" style={{ width: `${item.value}%`, backgroundColor: item.color }}>
              {item.value === 858 && <span className="alert-value">{item.value}</span>}
            </div>
          </div>
          <div className="value-box">
            <div>{item.value}</div>
            <div className="secondary-value">{item.secondaryValue}</div>
          </div>
        </div>
      ))
    ) : (
      <p>No data available</p> // Message when there is no data
    )}
    </div>
  );
};

export default SoilAnalysis;
