import React from 'react';

const progressBar = ({ progress }) => {
    const progresss = 100;
  return (
    <div style={styles.container}>
      <div style={{ ...styles.filler, width: `${progress}%` }}>
        <span style={styles.label}>{`${progress}%`}</span>
      </div>
    </div>
  );
};

const styles = {
    container: {
        height: '20px',
        width: '100%', // Ensure the container uses 100% width
        backgroundColor: '#e0e0df',
        borderRadius: '50px',
        margin: '20px 0',
        overflow: 'hidden', // Ensure the filler doesn't exceed the container
      },
  filler: {
    height: '100%',
    borderRadius: 'inherit',
    backgroundColor: '#4caf50',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontWeight: 'bold',
    transition: 'width 0.01s ease-in',
  },
  label: {
    padding: '5px',
    fontSize: '12px',
  },
};

export default progressBar;
